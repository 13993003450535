@import '../../base/variables';

.leads-container {
    img.favourite {
        padding-top: 3px;
    }
}

.MuiChip-labelSmall {
    padding: 5px 8px 3px 12px !important;
}

.view-enquiry {
    .MuiPaper-root {
        color: $dark-grey;
    }

    .MuiTabs-indicator {
        background-color: $dark-grey;
    }

    &-header {
        margin-bottom: 30px;
    }

    &-content {
        .notice {
            width: 100%;

            @media (min-width: $breakpoint-md) {
                width: 50%;
            }
        }

        .view-enquiry-applicants {
            .p4style-component-container {
                border-radius: 15px !important;

                .table-container {
                    border-bottom: 1px solid $light-grey;
                    padding-bottom: 20px;

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }
            }
        }

        .view-enquiry-matches {
            .app-bar {
                background-color: $main-bg;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                font-family: Roboto, Helvetica, Arial, sans-serif, serif;
                box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            }

            .matches-badge {
                @extend .typography-badge;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                width: 87px;
                height: 87px;
                margin-top: -15px;
                background-color: $red;
                color: $light-font;
                border: 4px solid $pink-dusky;
                border-radius: 100px;

                .count {
                    @extend .typography-badge-count;
                }
            }

            .table-head {
                .MuiTableCell-head {
                    padding-top: 12px;
                }
            }

            .message-container {
                @extend .typography-card-subtitle;
                justify-content: center;
                max-width: 300px;
                text-align: center;
                color: $dark-grey;
                margin: 60px 0 120px;

                a {
                    color: $dark-grey;
                }
            }
        }
    }
}
