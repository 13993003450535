@import '../../base/variables';

.status-widget-dropdown .input-dropdown {
    padding-top: 19px;
    color: $white;
}

.status-widget {
    border: 1px solid $light-grey;
    background: $white;
    border-radius: 20px;
    position: relative;
    height: 62px;
    width: 264px;
    padding-left: 14px;

    &-status {
        display: inline-block;
        padding: 12px 0 0 0;
        text-transform: capitalize;
    }

    &-steps {
        margin: 10px 0 0 0;
        display: flex;

        .status-step {
            display: inline-block;
            width: 24px;
            height: 7px;
            border-radius: 3.5px;
            margin: 0 3px 0 0;

            &-incomplete-color {
                background-color: $status-step-incomplete-color;
            }

            &-matched-color {
                background-color: $status-matched-color;
            }

            &-in-progress-color {
                background-color: $status-in-progress-color;
            }

            &-application-color {
                background-color: $status-application-color;
            }

            &-valuation-color {
                background-color: $status-valuation-color;
            }

            &-legals-color {
                background-color: $status-legals-color;
            }

            &-completed-color {
                background-color: $status-completed-color;
            }
        }
    }

    .status-button {
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid $light-grey;
        border-radius: 0 14px 14px 0;
        padding: 12px 12px 11px 12px;

        &-completed,
        &-completion-rejected,
        &-completion-requested {
            background-color: $status-completed-color;
            padding: 16px;

            &.Mui-disabled {
                background-color: $status-completed-color;
            }

            .tick-icon {
                width: 32px;
                height: 32px;
            }
        }

        &-completion-rejected {
            background-color: $status-completion-rejected-color;
            padding: 16px;

            &.Mui-disabled {
                background-color: $status-completion-rejected-color;
            }

            .cross-icon {
                width: 32px;
                height: 32px;
            }
        }

        .arrow-dropdown {
            color: $very-dark-blue;
            font-size: 40px;
        }

        &:disabled {
            .arrow-dropdown {
                color: $light-grey;
            }
        }
    }
}

.status {
    &-step-incomplete-color {
        color: $status-step-incomplete-color;
    }

    &-matched-color {
        color: $status-matched-color;
    }

    &-in-progress-color {
        color: $status-in-progress-color;
    }

    &-application-color {
        color: $status-application-color;
    }

    &-valuation-color {
        color: $status-valuation-color;
    }

    &-legals-color {
        color: $status-legals-color;
    }

    &-completion-requested-color {
        color: $status-completion-requested-color;
    }

    &-completed-color {
        color: $status-completed-color;
    }

    &-completion-rejected-color {
        color: $status-completion-rejected-color;
    }
}

.view-enquiry-header {
    .toolbar {
        display: flex;
        justify-content: flex-end;
        margin-right: 16px;
        margin-bottom: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .view-enquiry-header {
        .screen-title {
            padding-bottom: 0 !important;
        }

        .toolbar {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 514px) {
    .view-enquiry-header {
        .toolbar {
            justify-content: flex-start;
            flex-wrap: wrap;

            .loan-enquiry-context-menu {
                margin-left: 0;
            }

            .status-widget {
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (max-width: 442px) {
    .view-enquiry-header {
        .toolbar {
            .loan-enquiry-context-menu {
                margin-top: 20px;
            }
        }
    }
}
