@import '../base/variables';

.tabs-indicator-top-line {
    margin: 0 30px;
    height: 1px;
    background-color: $provide-grey-2;
    border: none;
    position: relative;
    top: 39px;
}

//.MuiPaper-root {
.MuiTabs-root {
    .MuiTabs-indicator {
        background-color: transparent;
        height: 20px;

        .tab-indicator-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .tab-active-indicator {
                width: 14px;
                height: 14px;
                background-color: $provide-teal;
                border-radius: 10px;
            }
        }
    }

    .MuiTab-wrapper {
        padding-bottom: 10px;
    }
}

//}
