@import '../../base/variables';

.completion-details {
    margin-right: 20px;
    display: flex;
    align-items: center;
    border: 1px solid $light-grey;
    padding: 10px;
    min-width: 250px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    .header {
        color: $provide-teal;
    }

    .value {
        color: $dark-grey;
    }
}
