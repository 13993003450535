@import '../base/variables';

.p4style-component-container {
    padding: $p4style-container-padding-vert $p4style-container-padding-horiz;
    border-radius: 15px !important;

    .p4style-component-header {
        @extend .typography-card-title;
    }

    .p4style-component-body {
        @extend .typography-card-data;
    }
}
