@import '../base/variables';

.MuiTypography-root {
    &.MuiPickersToolbarText-toolbarTxt {
        &.MuiTypography-subtitle1 {
            color: $white;
            text-decoration: underline;
            font-size: 1rem;
        }
    }
}
