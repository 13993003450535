@import '../../base/variables';

.sms-verification-modal-container {
    padding: 30px;

    .header {
        color: $dark-grey;

        .resend-code-container {
            display: flex;
            margin-top: 20px;

            .new-phone {
            }

            .btn-resend {
                margin-left: 5px;
                background-color: $provide-teal;
            }
        }

        .enter-code {
            margin-top: 20px;

            span {
                font-weight: 600;
                color: $provide-teal;
            }
        }

        .resend-code {
            font-weight: 600;
            text-align: center;

            .update-phone {
                color: $provide-teal;
                margin-top: 20px;
                font-weight: normal;
                cursor: pointer;
            }

            div {
                width: 100%;

                input {
                    text-align: center;
                }
            }
        }

        margin-bottom: 20px;
    }

    .content {
        margin: 0;
        padding-bottom: 0;

        .code-input {
            margin-bottom: 20px;
            text-align: center;

            div {
                width: 100%;

                input {
                    text-align: center;
                }
            }
        }

        .no-code-received {
            color: $provide-teal;
            margin-bottom: 60px;
            cursor: pointer;
            text-align: center;
        }
    }

    .actions {
        text-align: right;

        .btn-next {
            margin-left: 10px;
            background-color: $provide-teal;
        }
    }
}
