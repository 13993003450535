@import '../base/variables';

.pill {
    background-color: red;
    border-radius: 15px;
    color: white;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 140px;

    &.pill-role-intermediary {
        background-color: $provide-intermediary-color;
    }

    &.pill-role-borrower {
        background-color: $provide-borrower-color;
    }

    &.pill-role-slam {
        background-color: $provide-lender-color;
    }

    &.pill-role-lender {
        background-color: $provide-lender-color;
    }

    &.pill-role-admin {
        background: $provide-admin-color;
    }
}

.role-intermediary-hint {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: left;
    background-color: $provide-intermediary-color;
    margin: 0.2rem 0.5rem;
}

.role-borrower-hint {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: left;
    background-color: $provide-borrower-color;
    margin: 0.2rem 0.5rem;
}

.role-lender-hint {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: left;
    background-color: $snackbar-red;
    margin: 0.2rem 0.5rem;
}
