@import 'variables';

html,
body,
.typography-default {
    font-family: $provide-font-family-roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
}

.typography-page-title {
    font-size: 30px;
    font-family: $provide-font-family-degular-display;
    font-weight: 500;
    color: $provide-black;
}

.typography-card-title {
    font-family: $provide-font-family-degular-display;
    font-weight: 500;
    font-size: 18px;
    color: $provide-black;
    line-height: 1.4;
}

.typography-card-title-chunky {
    font-family: $provide-font-family-roboto;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
}

.typography-card-title-brand-blue {
    font-family: $provide-font-family-roboto;
    font-weight: 300;
    font-size: 18px;
    color: $very-dark-blue;
}

.typography-card-subtitle {
    font-family: $provide-font-family-degular-display;
    font-weight: 400 !important;
    font-size: 14px;
    color: $provide-grey-6;
}

.typography-card-data {
    font-family: $provide-font-family-degular-display;
    font-weight: 500;
    font-size: 14px;
    color: $provide-grey-6;
}

.typography-table-heading {
    font-family: $provide-font-family-roboto;
    font-weight: 500;
    font-size: 14px;
}

.typography-table-body {
    font-family: $provide-font-family-roboto;
    font-weight: 400;
    font-size: 14px;
}

.typography-pagination {
    font-family: $provide-font-family-roboto;
    font-weight: 400;
    font-size: 12px;
}

.typography-chat-icon {
    font-family: $provide-font-family-roboto;
    font-weight: normal;
    font-size: 12px;
}

.typography-small {
    font-family: $provide-font-family-roboto;
    font-weight: 400;
    font-size: 12px;
}

.typography-tooltip {
    font-family: $provide-font-family-roboto;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    font-style: normal;
}

.typography-button {
    font-family: $provide-font-family-roboto;
    font-weight: 700;
    font-size: 14px;
    color: $light-font;
}

.typography-checkbox-label,
.typography-radio-label,
.typography-snackbar-message,
.typography-authentication-body {
    font-family: $provide-font-family-roboto;
    font-weight: normal;
    font-size: 14px;
    color: $dark-grey;
}

.typography-placeholder-title {
    font-family: $provide-font-family-roboto;
    font-weight: normal;
    font-size: 30px;
    color: $pale-grey;
}

.typography-badge-count {
    font-family: $provide-font-family-roboto;
    font-weight: 300;
    font-size: 35px;
    line-height: 41px;
}

.typography-badge {
    font-family: $provide-font-family-roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.typography-field-error {
    font-size: 12px;
    color: $form-error;
}

.typography-default-link {
    color: $provide-teal;
}

.typography-bold-dark-grey {
    color: $dark-grey;
    font-weight: 500;
}

.typography-intermediary {
    color: $green;
    font-weight: 500;
}

.typography-borrower {
    color: $red;
    font-weight: 500;
}

.typography-lender-profile-text {
    font-family: $provide-font-family-roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
}
