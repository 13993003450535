@import '../../base/variables';

.stepper {
    .field-row {
        .operand-container {
            margin-top: 0;

            .checkbox {
                padding-left: 7px;
            }

            .operand-multiple-container {
                .checkbox-columns {
                    display: grid;
                    grid-auto-flow: row;
                    grid-template-columns: repeat(3, 1fr);
                }

                padding-top: 0;
                padding-bottom: 0;
            }

            .scrollable {
                overflow-y: scroll;

                .MuiFormHelperText-root {
                    margin-left: 13px;
                    margin-top: 0;
                }
            }
        }
    }
}

.business-stream-telephone-box-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: unset;
    padding-right: 20px;
    padding-left: 30px;

    .MuiGrid-item {
        padding: 0;
    }

    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        padding: 4px;
    }

    .business-stream-telephone-box {
        max-width: 600px;
    }

    .notice.notice-default {
        padding: 12px;
    }

    .notice .notice-icon .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
        margin-left: 11px;
        margin-right: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-47%);
        color: $provide-teal;
    }

    .notice-message {
        margin-left: 53px;
    }

    .notice-header {
        padding: 5px !important;
        font-weight: 500;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .stepper {
        .field-row {
            .operand-container {
                .operation-container,
                .not-scrollable {
                    padding-left: 20px;
                }

                .operation-container,
                .operand-single-container,
                .operand-multiple-container,
                .operand-start-container,
                .operand-end-container {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .stepper {
        .field-row {
            .operand-container {
                .operand-multiple-container {
                    .checkbox-columns {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }
}

//applicants specific
.field-row-auto-complete-cta {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 5px;
    }
}

.intermediary-mle,
.borrower-mle {
    .card {
        color: #ffffff;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        box-shadow: #00000033 0px 2px 1px -1px, #00000024 0px 1px 1px 0px, #0000001f 0px 1px 3px 0px;
        color: #000000de;
        display: block;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin-left: 30px;
        margin-top: 34px;
        padding-bottom: 45px;
        padding-left: 38px;
        padding-right: 38px;
        padding-top: 0px;
        transition-delay: 0s;
        transition-duration: 0.3s;
        transition-property: box-shadow;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    span.field-button-panel-row-item {
        display: inline-block;
        margin-right: 0;
    }

    .draft-le-buttons {
        display: flex;
        flex-direction: row;
    }

    .progress-buttons {
        button {
            margin-right: 20px;
        }
    }

    .delete-draft-button {
        padding: 0 0 0 20px;
    }

    .mle-submit-summary {
        .submit-summary-heading {
            color: $provide-black;
            font-weight: 500;
        }

        .submit-summary-subtitle {
            width: 60%;
            padding: 15px;
            background-color: $info-box-bg;
            font-family: $provide-font-family-roboto;
            font-weight: 400;

            .summary-help-icon {
                padding: 2px 15px 0 0;
                color: $provide-teal;
            }
        }
    }
}
