@import '../../base/variables';

.delete-tag-modal {
    .header {
        color: $provide-teal;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .text {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 1.5 !important;
        text-align: left;
    }

    .tag {
        padding: 3px 5px;
        border-radius: 4px;
    }
}
