.view-lead {
    .view-lead-header {
        .compare-icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            color: $red;
            margin: 0 10px;
            background: url(../images/compare_arrows.svg) transparent no-repeat;
            position: relative;
            top: 10px;
        }
    }
}
