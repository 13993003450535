.chat-form {
    .primary-button {
        width: 100%;
        white-space: nowrap;
        padding: 12px 35px;
    }
}

@media only screen and (max-width: 600px) {
    .chat-form {
        .primary-button.MuiButtonBase-root.MuiButton-containedPrimary {
            margin-right: 0;
        }
    }
}
