.message-list {
    @extend .typography-default;
    height: 100%;
    transition: all 2s ease-in-out 2s;

    .message-row {
        padding-bottom: 1em;
        transition: all 2s ease-in-out 2s;

        .message-body {
            @extend .typography-default;
        }

        .message-timestamp {
            @extend .typography-small;

            padding-top: 10px;
            float: right;
        }
    }
}

@media only screen and (max-width: 900px) {
    .message-row {
        .message-admin-logo {
            display: none;
        }
    }
}
