@import '../../base/variables';

.anon-dot-container {
    display: flex;

    .anon-dot {
        width: 10px;
        height: 10px;
        background-color: $provide-grey-6;
        border-radius: 10px;
        margin-right: 10px;
    }
}
