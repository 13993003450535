@import '../base/variables';

.dashboard-borrower {
    .view-all-link {
        font-family: $provide-font-family-roboto;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
        color: $dark-grey;
        display: flex;
        align-items: center;
    }

    .MuiButton-label {
        font-weight: 700;
    }

    .header-container {
        .help {
            span {
                color: $dark-grey !important;
                font-weight: 500;
            }
        }
    }

    .other-to-do {
        padding: 20px;
        margin-top: 20px;

        .header {
            padding-bottom: 20px;
            font-weight: 700;
            color: $dark-grey;
        }

        .box-2 {
            margin: 0 10px 0 20px;
        }

        .box-3 {
            margin: 0 20px 0 10px;
        }

        @media only screen and (max-width: 960px) {
            .box-2 {
                margin: 20px 0 20px 0;
            }

            .box-3 {
                margin: 0 0 20px 0;
            }
        }

        [class*=' box-'] {
            padding: 40px 20px 40px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 275px;
            text-align: center;
            border: 1px solid $light-grey;
            cursor: pointer;

            &:hover {
                background-color: $main-bg;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            }

            .header {
                padding: unset;
                margin-top: 20px;
                font-family: $provide-font-family-degular-display;
                font-weight: 500;
                font-size: 16px;
                color: $provide-black;
            }
        }
    }

    .le-container {
        .right-column {
            .process,
            .faqs {
                padding: 40px 20px 30px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 293px;
                justify-content: center;
                text-align: center;
                background-color: $main-bg;

                .header {
                    font-family: $provide-font-family-degular-display;
                    font-weight: 500;
                    font-size: 16px;
                    color: $provide-black;
                }
            }

            .faqs {
                margin-top: 20px;
            }
        }

        @media only screen and (max-width: 960px) {
            .right-column {
                margin-top: 20px;
            }
        }

        .status {
            .status-chip {
                height: 6px;
                width: 20px;
                margin-right: 5px;
            }

            .select {
                cursor: pointer;
                font-size: 25px;
                margin-bottom: -3px;
            }

            .status-matched,
            .status-in_progress,
            .status-application,
            .status-valuation,
            .status-legals,
            .status-completed,
            .status-ended,
            .status-completion_rejected,
            .status-completion_requested {
                color: $dark-grey;
                font-weight: 700;
            }

            .step-1 {
                background-color: $status-matched-color;
            }

            .step-2 {
                background-color: $status-in-progress-color;
            }

            .step-3 {
                background-color: $status-application-color;
            }

            .step-4 {
                background-color: $status-valuation-color;
            }

            .step-5 {
                background-color: $status-legals-color;
            }

            .step-6 {
                background-color: $status-completed-color;
            }
        }

        .main-le {
            margin-right: 20px;
            padding: 20px;
            background-color: $main-bg;

            .recent-le {
                margin-bottom: 20px;
                border: 1px solid $light-grey;

                .box-1 {
                }

                .box-2 {
                    .loan-purpose-header,
                    .loan-note-header,
                    .applicant-header {
                        padding-top: 11px;
                        font-weight: 700;
                        font-size: 14px;
                        color: $provide-grey-6;
                    }

                    .loan-purpose-value,
                    .loan-note-value,
                    .applicant-value {
                        font-size: 16px;
                        margin-bottom: 20px;
                        font-weight: 400;
                    }

                    @media only screen and (max-width: 960px) {
                        .applicant-header {
                            margin-top: 20px;
                        }
                    }
                }

                .box-3 {
                    .business-name-header,
                    .business-sector-header,
                    .property-value-header,
                    .property-type-header {
                        padding-top: 10px;
                        font-weight: 700;
                        font-size: 14px;
                        color: $provide-grey-6;
                    }

                    .business-name-value,
                    .business-sector-value,
                    .property-type-value,
                    .property-value-value {
                        font-size: 16px;
                        margin-bottom: 20px;
                        font-weight: 400;
                        color: $provide-black;
                    }
                }
            }

            .empty-le {
                height: 280px;
                border: 1px dashed $light-grey;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $main-bg;
            }

            .le-card {
                height: 280px;
                border: 1px solid $light-grey;
                border-radius: 15px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: $provide-black;

                .mobile-le-view-btn {
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                .amount {
                    font-size: 30px;
                    font-weight: 700;
                }

                .type {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $provide-font-family-degular-display;
                }

                .matches-count {
                    background-color: #0d0a24;
                    color: $white;
                    padding: 5px 15px;
                    border-radius: 20px;
                    width: fit-content;
                    width: -moz-fit-content;
                    margin-top: 20px;
                }

                .dates {
                    display: flex;
                    color: $dark-grey;
                    margin-top: 20px;

                    .created {
                        .header {
                            font-weight: 700;
                            font-size: 14px;
                            margin: 0;
                        }

                        .date {
                            font-size: 16px;
                            color: $provide-black;
                        }
                    }

                    .updated {
                        .header {
                            font-weight: 700;
                            font-size: 14px;
                            color: $provide-grey-6;
                            margin: 0;
                        }

                        .date {
                            font-size: 16px;
                            color: $provide-black;
                        }
                    }

                    .divider {
                        border: 1px solid $light-grey;
                        margin: 0 20px;
                    }
                }
            }
        }

        .other-le-skeleton {
            border: 1px solid $light-grey;
            border-radius: 4px;
            padding: 20px;
        }

        .other-le {
            cursor: pointer;

            &:hover {
                background-color: $main-bg;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            }
        }

        @media only screen and (max-width: 960px) {
            .main-le {
                margin-right: 0;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                color: $dark-grey;
                font-size: 1rem;
                text-align: unset;
                padding: 0;
                margin: 0;
                width: unset;
                font-family: $provide-font-family-roboto !important;
                font-weight: 700;
            }

            .link {
            }
        }
    }
}

@media only screen and (max-width: 735px) {
    .dashboard-borrower {
        .header-container {
            display: block;

            .help {
                margin-top: 20px;

                .text {
                }
            }
        }
    }
}
