@import '../base/variables';

.dashboard-slam {
    .MuiAccordion-root:before {
        height: 0;
    }

    .header-container {
        .help {
            color: $dark-grey !important;
        }
    }

    .main-container {
        .main-le {
            margin-right: 20px;
            padding: 0;

            .MuiPaper-rounded {
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-top-right-radius: 15px !important;
            }

            .summary {
                padding: 0 20px 0 0;
                background-color: $main-bg;
                min-height: 104px;
                border-top-right-radius: 15px !important;

                .MuiAccordionSummary-content {
                    margin: 0;
                    border-radius: 15px;
                }

                .header-container {
                    font-weight: 600;

                    .value {
                        font-size: 2rem;
                        font-weight: normal;
                    }

                    .new-leads,
                    .leads-in-progress,
                    .completed-leads,
                    .leads-in-application {
                        color: $white;
                        min-height: 104px;
                        padding: 20px 0 0 6px;
                        border-top-left-radius: 15px;
                        font-weight: 700;

                        .value {
                            font-weight: 500;
                            font-size: 27px;
                        }

                        div {
                            padding-left: 14px;
                        }
                    }

                    .new-leads {
                        background-color: $provide-slam-dashboard-new-leads-color;
                    }

                    .completed-leads {
                        background-color: $supporting-cyan;
                    }

                    .leads-in-progress {
                        background-color: $provide-slam-dashboard-leads-in-progress-color;
                    }

                    .leads-in-application {
                        background-color: $provide-slam-dashboard-leads-in-application-color;
                    }

                    .borrower-leads,
                    .intermediary-leads,
                    .total-value {
                        font-weight: 700;
                    }

                    .borrower-leads,
                    .intermediary-leads {
                        .value {
                            font-size: 27px;
                        }
                    }

                    .total-value {
                        .value {
                            font-size: 23px;
                        }
                    }

                    .borrower-leads,
                    .intermediary-leads {
                        color: $dark-grey;
                        min-height: 104px;
                        padding: 20px 0 0 20px;
                        background-color: $main-bg;
                        border-right: 1px dashed $light-grey;

                        .value {
                            color: $medium-grey;
                        }
                    }

                    .total-value {
                        color: $dark-grey;
                        min-height: 104px;
                        background-color: $main-bg;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .content {
                margin: unset;
                padding: unset;

                .content-container {
                    background-color: $main-bg;
                    display: flex;
                    padding-right: 50px;

                    .new-leads {
                        background-color: $provide-slam-dashboard-new-leads-color;
                    }

                    .completed-leads {
                        background-color: $supporting-cyan;
                    }

                    .leads-in-progress {
                        background-color: $provide-slam-dashboard-leads-in-progress-color;
                    }

                    .leads-in-application {
                        background-color: $status-application-color;
                    }

                    .content-leads {
                        display: flex;
                        padding-left: 10px;
                        padding-bottom: 10px;

                        .lead-card {
                            margin: 10px;
                            border: 1px solid $light-grey;
                            padding: 20px;
                            max-height: 435px;
                            border-radius: 15px !important;

                            &:hover {
                                background-color: $main-bg;
                                cursor: pointer;
                                box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                            }

                            .lead {
                                .amount {
                                    margin-bottom: 5px;
                                    font-size: 30px;
                                    color: $dark-grey;
                                    font-weight: 700;
                                }

                                .notes,
                                .needed-by,
                                .ltv {
                                    .label {
                                        padding-top: 10px;
                                        font-weight: 700;
                                        size: 11px;
                                        color: $provide-grey-6;
                                    }

                                    .note,
                                    .value {
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: $medium-grey;
                                    }
                                }

                                .notes {
                                    margin-bottom: 20px;

                                    .note {
                                        overflow: auto;
                                        max-height: 140px;
                                    }
                                }

                                .loan-type {
                                    margin-bottom: 15px;
                                    font-size: 16px;
                                    color: $dark-grey;
                                    font-weight: 500;
                                }

                                .applicant {
                                    margin-bottom: 15px;

                                    .name {
                                        font-size: 18px;
                                        color: $provide-grey-6;
                                        margin-bottom: 10px;
                                    }
                                }

                                .user-type {
                                    color: $white;
                                    text-align: center;
                                    border-radius: 20px;
                                    max-width: 100px;
                                    font-size: 13px;
                                    padding: 5px 30px;
                                }

                                .user-type-borrower {
                                    background-color: $provide-borrower-color;
                                }

                                .user-type-intermediary {
                                    background-color: $provide-intermediary-color;
                                }

                                .date-posted,
                                .date-updated {
                                    .label {
                                        font-weight: 700;
                                        size: 11px;
                                        color: $provide-grey-6;
                                        margin: 0;
                                    }

                                    .date {
                                        font-size: 16px;
                                    }
                                }

                                .dates {
                                    display: flex;
                                    justify-content: space-between;
                                    color: $dark-grey;
                                    margin-top: 20px;

                                    .updated {
                                        .date {
                                            font-size: 1.3rem;
                                        }
                                    }

                                    .created {
                                        .date {
                                            font-size: 1.3rem;
                                        }
                                    }

                                    .divider {
                                        border: 1px solid $light-grey;
                                        margin: 0 20px;
                                    }
                                }
                            }

                            .label {
                                font-size: 0.8rem;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .footer-container {
                background-color: $main-bg;

                .view-all-link {
                    font-family: $provide-font-family-roboto;
                    font-size: 18px;
                    color: $provide-grey-5;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }

        .right-column {
            select {
                font-family: $provide-font-family-roboto !important;
                font-size: 14px;
                font-weight: 400;
                color: $dark-grey;
            }

            .completions-stats,
            .lead-stats {
                padding: 20px 20px 20px 20px;
                background-color: $main-bg;
                color: $dark-grey;

                .stats-period-container {
                    width: 100%;
                    margin-bottom: 20px;
                }

                .avg-lead-value {
                    .label {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .value {
                        font-size: 23px;
                        font-weight: 400;
                    }
                }

                .total-leads {
                    .label {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .value {
                        font-size: 23px;
                        font-weight: 400;
                    }
                }

                .total-value {
                    .label {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .value {
                        font-size: 23px;
                        font-weight: 400;
                    }
                }

                .header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $provide-font-family-degular-display;
                    font-weight: 700;
                    font-size: 18px;

                    .icon {
                        color: $white;
                        margin: 5px;
                    }

                    .icon-container {
                        background-color: $provide-purple;
                        color: $white;
                        display: flex;
                        margin-right: 10px;
                        border-radius: 15px;
                    }
                }

                .item {
                    margin-bottom: 10px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .completions-stats {
                margin-top: 20px;

                .total-completions {
                    .label {
                        font-size: 0.8rem;
                        font-weight: 600;
                    }

                    .value {
                        font-size: 1.2rem;
                        margin-bottom: 20px;
                        color: $dark-grey;
                    }
                }
            }

            .toolbox {
                margin-top: 20px;
                padding: 20px 20px 10px 20px;
                background-color: $main-bg;
                color: $dark-grey;

                .header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $provide-font-family-degular-display;
                    font-weight: 700;
                    font-size: 18px;

                    .icon {
                        color: $white;
                        margin: 5px;
                    }

                    .icon-container {
                        background-color: $provide-purple;
                        color: $white;
                        display: flex;
                        margin-right: 10px;
                        border-radius: 15px;
                    }
                }

                .item {
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;

                    a {
                        color: $dark-grey;
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .resources {
                margin-top: 20px;
                padding: 20px 20px 10px 20px;
                background-color: $main-bg;
                color: $dark-grey;

                .header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $provide-font-family-degular-display;
                    font-weight: 700;
                    font-size: 18px;

                    .icon {
                        color: $white;
                        margin: 5px;
                    }

                    .icon-container {
                        background-color: $provide-purple;
                        color: $white;
                        display: flex;
                        margin-right: 10px;
                        border-radius: 15px;
                    }
                }

                .item {
                    margin-bottom: 10px;
                    cursor: pointer;

                    a {
                        color: $dark-grey;
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                color: $dark-grey;
                font-size: 1rem;
                text-align: unset;
                padding: 0;
                margin: 0;
                width: unset;
            }

            .link {
            }
        }

        @media only screen and (max-width: 960px) {
            .right-column {
                margin-top: 20px;
            }
        }
    }
}

@media only screen and (max-width: 867px) {
    .dashboard-slam {
        .main-container {
            .main-le {
                margin-right: 0;
            }
        }

        .header-container {
            display: block;

            .help {
                margin-top: 20px;

                .text {
                }
            }
        }
    }
}
