@import '../../base/variables';

.registration {
    &-form {
        line-height: 1.6;
        width: 100%;

        .privacy-panel {
            margin-top: 20px;

            .typography-default {
                @extend .typography-default;
                color: $dark-grey;
                font-size: 13px;
            }
        }

        &__heading {
            color: $very-light-grey;
            width: 14em;
            text-align: center;
            margin-top: 0;
            margin-bottom: 1.7em;
        }

        &__error-heading {
            color: $very-dark-blue;
            margin: 0 0 10px 0;
        }

        &__step-heading {
            margin-top: 0;
            margin-bottom: 0;
            color: $dark-grey;

            &--highlight {
                color: $provide-teal;
            }
        }

        &__form {
            width: 100%;
        }

        &__t-and-cs {
            margin-top: 31px;
        }

        .checkbox {
            .MuiFormControlLabel-root {
                position: relative;
                margin-left: -15px;
            }
        }

        .terms-checkbox .checkbox {
            .MuiFormControlLabel-root .MuiFormControlLabel-label {
                font-size: 12px;

                a {
                    color: $provide-teal;
                }
            }
        }
    }

    &-ts-and-cs {
        line-height: 1.6;

        .typography-card-subtitle-brand-blue {
            @extend .typography-card-subtitle;
            color: $very-dark-blue;
        }

        &__text {
            margin-bottom: 15px;
            color: $medium-grey;

            a {
                color: $provide-teal;
            }

            &-heading {
                color: $dark-grey;
            }
        }
    }

    &-links {
        margin-top: 20px;

        #login {
            text-decoration: underline;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .registration-form {
        &__step-heading {
            margin-bottom: 5px;
        }
    }
}

// background gradients for each step
.register {
    &-step-2 {
        background: linear-gradient(359.92deg, #ec407a -3.26%, #0c0a3e 116.84%);
        mix-blend-mode: multiply;
    }

    &-step-3 {
        background: linear-gradient(33.48deg, #ec407a 14.03%, #0c0a3e 84.34%);
        mix-blend-mode: multiply;
    }

    &-step-4 {
        background: linear-gradient(89.01deg, #ec407a 22.28%, #0c0a3e 104.59%);
        mix-blend-mode: multiply;
    }
}

.content-container--hr-top {
    border-top: 1px solid $light-grey;
}
