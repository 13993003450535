@import '../../base/variables';

.data-grid-table-container {
    height: 50vh;
    width: 100%;
    background-color: $white;

    .data-grid-no-border {
        border: none;
    }

    .data-grid-alert {
        background-color: #33cac8;
        color: $white;
        font-family: Roboto, Helvetica, Arial, sans-serif, serif;
        font-size: 14px;
    }

    .MuiDataGrid-root {
        border-radius: 0;
    }

    .MuiDataGrid-row {
        cursor: pointer;

        &:hover {
            .data-grid-table-cell-context-menu {
                opacity: 1;
            }
        }
    }

    .MuiTablePagination-select {
        font-family: $provide-font-family-roboto !important;
    }

    .MuiDataGrid-colCell,
    .MuiDataGrid-footer,
    .MuiTablePagination-root p {
        @extend .typography-default;
        color: $provide-black;
    }

    .data-grid-table-cell {
        @extend .typography-default;
        color: $provide-black;

        .active {
            color: $provide-teal;
        }

        .terms-added {
            color: $provide-teal;
        }

        .terms-empty {
            color: $medium-grey;
        }

        .lender-name {
            display: flex;
            flex-direction: column;
            line-height: normal;

            .anon-name {
                color: #888888;
                font-size: 12px;
            }
        }
    }

    .data-grid-table-cell-context-menu {
        opacity: 0;
    }

    .data-grid-table-header-fixed-height {
        min-height: 56px;
    }

    .data-grid-table-header-no-separator {
        .MuiDataGrid-columnSeparator {
            display: none;
        }
    }

    .data-grid-table-cell-status > div {
        line-height: 1.5 !important;
        width: 50%;
    }
}
