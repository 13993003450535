@import '../../base/variables';

.tag-list-color {
    border-radius: 4px;
    width: 100%;
    text-align: center;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
}
