@import '../base/variables';

.admin-case-management-drawer {
    height: 100vh;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 0 40px;
        z-index: 9999;
        background-color: $white;

        .title {
            color: $provide-grey-5;
            font-size: 20px;
            text-align: left;
        }
    }

    .description {
        background-color: $white;
        padding: 0 40px;
        color: $provide-grey-5;
        font-weight: 200;
        font-family: $provide-font-family-roboto;

        strong {
            color: $provide-teal;
        }
    }

    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        background: $white;
        max-width: 680px;
        padding: 0 0 0 0;
        box-shadow: none;

        .document-list {
            .document-row {
                padding: 20px 0 20px 0;

                .doc-actions {
                    padding-right: 20px;
                }
            }
        }
    }
}
