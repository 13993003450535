@import '../../base/variables';

.document-list {
    .document-list-header {
        display: flex;
    }

    .document-row {
        transition: background-color 0.5s ease;
        padding: 10px 20px 5px 0;
        display: flex;
        justify-content: space-between;

        &:hover {
            background-color: $very-light-grey;
        }

        .doc-icon {
            padding-left: 20px;

            svg {
                width: 24px;
                height: 24px;
                color: $light-grey;
            }
        }

        .doc-type,
        .doc-filename {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 5px;
        }

        .doc-type {
            @extend .typography-card-data;
            color: $provide-black;
        }

        .doc-filename {
            @extend .typography-default;
            color: $provide-black;

            &:hover {
                cursor: pointer;
            }
        }

        .doc-created {
            color: $provide-black;
        }

        .doc-actions {
            transition: opacity 0.5s ease;
            display: flex;
            justify-content: flex-end;
            height: 100%;
            width: 100px;

            .cloud-icon,
            .delete-icon {
                $icon-height: 24px;
                width: 24px;
                height: 24px;
                color: $medium-grey;

                &:hover {
                    color: black;
                    cursor: pointer;
                }
            }

            .delete-icon {
                margin-left: 10px;

                &:hover {
                    color: $red;
                    cursor: pointer;
                }
            }
        }
    }
}

.documents-table {
    width: 100%;
    padding: 20px;
    border-spacing: 0;

    .doc-table-header {
        th:nth-child(2) {
            padding-left: 15px;
            text-align: left;
        }

        th:nth-child(3) {
            padding-left: 10px;
            text-align: left;
        }

        th:nth-child(4) {
            padding-left: 10px;
            text-align: left;
        }

        th:nth-child(5) {
            padding-left: 10px;
            text-align: left;
        }
    }

    .document-row {
        &:hover {
            background-color: $very-light-grey;
        }

        .doc-cell {
            padding: 10px;
        }

        .doc-icon {
            text-align: center;

            svg {
                width: 24px;
                height: 24px;
                color: $light-grey;
            }
        }

        .doc-type,
        .doc-filename {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 5px;
        }

        .doc-type {
            @extend .typography-card-data;
            color: $very-dark-blue;
        }

        .doc-filename {
            @extend .typography-default;
            color: $medium-grey;

            &:hover {
                cursor: pointer;
            }
        }

        .doc-actions {
            transition: opacity 0.5s ease;
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            padding-right: 10px;

            .cloud-icon,
            .delete-icon {
                $icon-height: 24px;
                width: 24px;
                height: 24px;
                color: $medium-grey;

                &:hover {
                    color: black;
                    cursor: pointer;
                }
            }

            .delete-icon {
                margin-left: 10px;

                &:hover {
                    color: $red;
                    cursor: pointer;
                }
            }
        }
    }
}
