@import '../../../base/variables';

textarea {
    @extend .typography-default;
}

.text-area {
    textarea {
        @extend .typography-default;
        line-height: 24px;
        color: $medium-grey;

        &:focus {
            background-color: $very-light-grey;
        }
    }

    .MuiInputBase-inputMultiline {
        background: $main-bg;

        &:focus {
            background: $very-light-grey;
        }

        border: 0.5px solid $light-grey;
        box-sizing: border-box;
        border-radius: 2px;
        height: 65px;
        padding: 12px 20px;
    }

    .text-area-auto-height {
        textarea {
            @extend .typography-default;
            height: auto;
            line-height: 24px;
            color: $medium-grey;

            &:focus {
                background-color: $very-light-grey;
            }
        }
    }

    .MuiFormHelperText-root {
        @extend .typography-field-error;
        margin: 8px 0 0 12px;
    }
}
