.account-settings {
    margin-top: 40px;

    .account-settings-item {
        margin-bottom: 40px;
    }
    .field-row {
        padding: 0;
        border-bottom: unset;
    }
    .create-reset-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 1rem;
        margin: 2rem 0 0 0;
        .secondary-button {
            background-color: $very-dark-blue !important;
            color: white;
        }
        .primary-button {
            margin: 0;
        }
    }

    .resend-verification-section {
        .p4style-component-header {
            @extend .secondary-colour;
        }
        .label-container {
            min-height: 47px;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .notice {
            padding: 15px;
            margin: 40px 0;
        }
        .button-panel {
            margin-top: 40px;
        }
    }
}
