@import '../base/variables';

.dashboard-admin {
    .MuiAccordion-root:before {
        height: 0;
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 20px;

        .unassigned-enquiries-icon,
        .assigned-enquiries-icon,
        .enquiries-with-updates-icon,
        .new-accounts-icon,
        .chart-header-icon,
        .favourite-enquiries-icon,
        .enquiry-owner-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border-radius: 50%;

            &.span {
                font-weight: 400;
                font-size: 13px;
                color: $medium-grey;
            }
        }

        .unassigned-enquiries-icon {
            color: $provide-admin-dashboard-unassigned-color;
            background-color: $provide-admin-dashboard-unassigned-color-faded-20;
        }

        .assigned-enquiries-icon {
            color: $provide-admin-dashboard-my-assigned-color;
            background-color: $provide-admin-dashboard-my-assigned-color-faded-20;
        }

        .favourite-enquiries-icon {
            color: $provide-admin-dashboard-my-favourite-color;
            background-color: $provide-admin-dashboard-my-favourite-color-faded-20;
        }

        .enquiries-with-updates-icon {
            color: $provide-admin-dashboard-enq-with-updates-color;
            background-color: $provide-admin-dashboard-enq-with-updates-color-faded-20;
        }

        .new-accounts-icon {
            color: $provide-admin-dashboard-new-borrower-accounts-color;
            background-color: $provide-admin-dashboard-new-borrower-accounts-color-faded-20;
        }

        .chart-header-icon {
            color: $dark-grey;
            background-color: $light-grey;
        }

        .enquiry-owner-icon {
            color: $provide-grey-9;
            background-color: $provide-grey-10;
        }
    }

    .header-container {
        margin-bottom: 20px;
    }

    .basic-stats-container {
        margin-bottom: 20px;

        .card {
            background-color: $main-bg;
            padding: 10px 20px 20px 20px;
            display: flex;
            cursor: pointer;
            font-family: $provide-font-family-roboto;
            border-radius: 20px;

            &:hover {
                background-color: $white;
            }

            .label {
                size: 14px;
                color: $provide-grey-6;
            }

            .count {
                font-size: 64px;
                font-weight: 700;
                color: $light-grey;
            }
        }
    }

    .main-chart-container {
        background-color: $main-bg;
        border-radius: 15px;

        .chart-container {
            padding: 20px;
            border-right: 1px solid $medium-light-grey;

            .header {
                margin-bottom: 20px;

                .icon-container {
                    justify-content: flex-start;
                    color: $dark-grey;
                    font-family: $provide-font-family-degular-display;
                    font-weight: 500;
                    font-size: 18px;

                    .chart-header-icon {
                        margin-right: 10px;
                    }
                }
            }

            .chart {
                padding: 0 20px 0 0;
            }
        }

        .filters-container {
            padding: 20px;
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
            font-family: $provide-font-family-roboto;
            color: $provide-grey-6;

            .MuiOutlinedInput-root {
                font-family: $provide-font-family-roboto;
                color: $provide-grey-6;
            }

            .MuiSelect-outlined.MuiSelect-outlined {
                background-color: $provide-grey-8;
            }

            .chart-stats-header {
                font-weight: 600;
                font-size: 1.2rem;
                color: $dark-grey;
            }

            .left-column {
                padding: 0 10px 0 0;

                .filter-type-select {
                    width: 100%;
                    margin-bottom: 40px;

                    .label-bold {
                        font-weight: 600;
                    }
                }
            }

            .right-column {
                padding: 0 0 0 10px;

                .filter-type-select {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }

            .stats-end-of-last-month {
                .last-month {
                    color: $light-grey;
                }
            }

            .stats-today,
            .stats-end-of-last-month,
            .stats-new-this-month,
            .stats-increase-from-previous-month {
                .header {
                    font-size: 14px;
                }

                .value {
                    color: $light-grey;
                    font-size: 40px;
                    font-weight: 700;
                }
            }

            .stats-new-this-month,
            .stats-increase-from-previous-month {
                .value {
                    color: $provide-teal;
                }
            }
        }
    }

    .lists-container {
        margin-top: 20px;

        .MuiPaper-rounded {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        .content {
            margin: unset;
            padding: unset;

            .content-container {
                background-color: $main-bg;
                display: flex;
                padding-right: 50px;

                .unassigned-enquiries {
                    background-color: $provide-admin-dashboard-unassigned-color;
                }

                .my-assigned-enquiries {
                    background-color: $provide-admin-dashboard-my-assigned-color;
                }

                .my-favourited-enquiries {
                    background-color: $ultra-light-grey;
                }

                .enquiries-with-updates {
                    background-color: $provide-admin-dashboard-enq-with-updates-color;
                }

                .new-accounts {
                    background-color: $provide-admin-dashboard-new-borrower-accounts-color;
                }

                .content-leads {
                    display: flex;
                    padding-left: 10px;
                    padding-bottom: 10px;

                    .btn-load-more-less {
                        background-color: $provide-teal;
                        box-shadow: none;
                    }

                    .lead-card {
                        margin: 10px;
                        border: 1px solid $light-grey;
                        padding: 20px;
                        border-radius: 15px !important;

                        &:hover {
                            background-color: $main-bg;
                            cursor: pointer;
                            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                        }

                        .lead {
                            .amount {
                                margin-bottom: 5px;
                                font-size: 23px;
                                color: $dark-grey;
                            }

                            .loan-type {
                                margin-bottom: 10px;
                                font-size: 16px;
                                font-weight: 700;
                                color: $provide-grey-5;
                                font-family: $provide-font-family-degular-display;
                            }

                            .applicant {
                                margin-bottom: 15px;

                                .name {
                                    font-size: 16px;
                                    color: $medium-grey;
                                    margin-bottom: 10px;
                                }

                                .email {
                                    color: $medium-grey;
                                }

                                .user-type,
                                .le-status {
                                    color: $white;
                                    text-align: center;
                                    border-radius: 20px;
                                    max-width: 100px;
                                    font-size: 13px;
                                    padding: 5px 20px;
                                    margin: 5px 0;
                                }

                                .user-type-borrower {
                                    background-color: $provide-borrower-color;
                                    font-family: $provide-font-family-arial;
                                    font-weight: 700;
                                }

                                .user-type-intermediary {
                                    background-color: $provide-intermediary-color;
                                    font-family: $provide-font-family-arial;
                                    font-weight: 700;
                                }

                                .le-status {
                                    &.matched {
                                        background-color: $status-matched-color;
                                    }

                                    &.in_progress {
                                        background-color: $status-in-progress-color;
                                    }

                                    &.application {
                                        background-color: $status-application-color;
                                    }

                                    &.valuation {
                                        background-color: $status-valuation-color;
                                    }

                                    &.legals {
                                        background-color: $status-legals-color;
                                    }

                                    &.completed {
                                        background-color: $status-completed-color;
                                    }

                                    &.on_hold,
                                    &.match_pending {
                                        background-color: $provide-black;
                                    }

                                    &.lender_declined {
                                        background-color: $status-lender-declined-color;
                                    }

                                    &.withdrawn_edited,
                                    &.ended,
                                    &.withdrawn {
                                        background-color: $status-withdrawn-color;
                                    }
                                }
                            }

                            .notes,
                            .needed-by,
                            .ltv {
                                .label {
                                    padding-top: 10px;
                                    font-weight: 700;
                                    font-size: 11px;
                                    color: $provide-grey-6;
                                }

                                .value,
                                .note {
                                    font-weight: 400;
                                    color: $medium-grey;
                                }

                                .note {
                                    font-size: 14px;
                                }

                                .value {
                                    font-size: 16px;
                                }
                            }

                            .notes {
                                margin-bottom: 20px;

                                .note {
                                    overflow: auto;
                                    max-height: 140px;
                                }
                            }

                            .date-posted,
                            .date-updated {
                                .date {
                                    font-size: 1.1rem;
                                    font-weight: 600;
                                }
                            }

                            .footer {
                                display: flex;
                                justify-content: space-between;
                                color: $dark-grey;
                                margin-top: 20px;
                                font-weight: 700;
                                font-size: 11px;

                                .updated {
                                    .date {
                                        font-size: 16px;
                                    }
                                }

                                .created {
                                    .date {
                                        font-size: 16px;
                                        font-weight: 700;
                                    }
                                }

                                .divider {
                                    border: 1px solid $light-grey;
                                    margin: 0 20px;
                                }

                                .owner {
                                    display: flex;

                                    .owner-name {
                                        margin-left: 10px;
                                        color: $medium-grey;
                                        font-size: 13px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }

                        .label {
                            font-size: 0.8rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .summary {
            padding: 0 20px 0 0;
            background-color: $main-bg;
            min-height: 104px;
            border-radius: 0 20px 0 0;

            .MuiAccordionSummary-content {
                margin: 0;
                border-radius: 15px;
            }

            .header-container {
                font-weight: 600;
                margin: 0;
                border-radius: 15px;

                .value {
                    font-size: 27px;
                    font-weight: 400;
                    color: $provide-grey-6;
                    font-family: $provide-font-family-roboto !important;
                }

                .unassigned-enquiries,
                .my-assigned-enquiries,
                .my-favourited-enquiries,
                .enquiries-with-updates,
                .new-accounts {
                    color: $white;
                    min-height: 104px;
                    padding: 20px 0 0 6px;
                    border-top-left-radius: 15px;
                    font-family: $provide-font-family-roboto !important;
                    font-weight: 700;
                    font-size: 14px;

                    div {
                        padding-left: 14px;
                    }

                    .value {
                        color: $white;
                        font-family: $provide-font-family-degular-display;
                        font-weight: 500;
                        font-size: 27px;
                    }
                }

                .unassigned-enquiries {
                    background-color: $provide-admin-dashboard-unassigned-color;
                }

                .my-assigned-enquiries {
                    background-color: $provide-admin-dashboard-my-assigned-color;
                }

                .my-favourited-enquiries {
                    background-color: $ultra-light-grey;
                    color: $dark-grey;

                    .value {
                        color: $dark-grey;
                    }

                    .header {
                        display: flex;
                        justify-content: space-between;

                        .icon {
                            margin-right: 20px;
                        }
                    }
                }

                .enquiries-with-updates {
                    background-color: $provide-admin-dashboard-enq-with-updates-color;
                }

                .new-accounts {
                    background-color: $provide-admin-dashboard-new-borrower-accounts-color;
                }

                .intermediary-leads {
                    color: $dark-grey;
                    min-height: 104px;
                    padding: 20px 0 0 20px;
                    background-color: $main-bg;
                    border-right: 1px dashed $light-grey;
                    font-family: $provide-font-family-roboto;
                    font-size: 14px;
                    font-weight: 700;

                    .value {
                        color: $medium-grey;
                    }
                }

                .borrower-leads {
                    color: $dark-grey;
                    min-height: 104px;
                    padding: 20px 0 0 20px;
                    background-color: $main-bg;
                    border-right: 1px dashed $light-grey;
                    font-family: $provide-font-family-roboto;
                    font-weight: 700;
                    font-size: 14px;

                    .value {
                        color: $medium-grey;
                    }
                }

                .total-value {
                    color: $dark-grey;
                    min-height: 104px;
                    background-color: $main-bg;
                    display: flex;
                    justify-content: space-between;
                    font-family: $provide-font-family-roboto !important;
                    font-weight: 700;
                    font-size: 14px;

                    .value {
                        font-family: $provide-font-family-roboto !important;
                        font-weight: 400;
                        font-size: 23px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .dashboard-admin {
        .main-chart-container {
            .filters-container {
                .chart-stats-header {
                    font-weight: 600;
                    font-size: 1.2rem;
                    color: $dark-grey;
                    margin-bottom: 20px;
                }

                .left-column {
                    padding: 0 0 0 0;
                }

                .right-column {
                    padding: 0 0 0 0;
                }
            }
        }

        .lists-container {
            .summary {
                .header-container {
                    .borrower-leads {
                        border-right: none;
                    }
                }
            }

            .content {
                .content-container {
                    .content-leads {
                        .lead-card {
                            .lead {
                                .footer {
                                    .owner {
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-admin {
        .lists-container {
            .unassigned-enquiries {
                .summary {
                    background-color: $provide-grey-5;

                    .MuiAccordionSummary-expandIcon {
                        display: none;
                    }
                }
            }

            .my-assigned-enquiries {
                .summary {
                    background-color: $status-application-color;

                    .MuiAccordionSummary-expandIcon {
                        display: none;
                    }
                }
            }

            .my-favourited-enquiries {
                .summary {
                    background-color: $ultra-light-grey;

                    .MuiAccordionSummary-expandIcon {
                        display: none;
                    }

                    .my-favourited-enquiries {
                        .header {
                            .icon {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .enquiries-with-updates {
                .summary {
                    background-color: $provide-supporting-cyan;

                    .MuiAccordionSummary-expandIcon {
                        display: none;
                    }
                }
            }

            .new-accounts {
                .summary {
                    background-color: $provide-purple;

                    .MuiAccordionSummary-expandIcon {
                        display: none;
                    }
                }
            }
        }
    }
}
