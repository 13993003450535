@import '../base/variables';

.dashboard-intermediary {
    .view-all-link {
        font-family: $provide-font-family-roboto;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
        color: $provide-grey-5;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .main-container {
        .right-column {
            .powered-by {
                text-align: center;
                padding: 40px;
                background-color: $main-bg;

                .logo {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;

                    img {
                        width: 168px;
                    }
                }

                .text {
                    font-family: Roboto, Helvetica, Arial, sans-serif !important;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.3;
                    margin-bottom: 20px;
                }
            }

            .stats {
                text-align: center;
                margin-top: 20px;
                padding: 10px;
                background-color: $main-bg;
                color: $dark-grey;

                .label {
                    font-weight: 700;
                    font-size: 14px;
                    color: $provide-grey-6;
                    font-family: $provide-font-family-roboto;
                }

                .number {
                    font-size: 40px;
                    font-weight: 700;
                    font-family: $provide-font-family-roboto;
                }

                .active-le {
                    .label {
                        font-weight: 600;
                    }

                    .number {
                        color: $very-dark-blue;
                    }
                }

                .le-updates {
                    .label {
                        font-weight: 600;
                    }

                    .number {
                        color: $provide-pink;
                    }
                }

                .completed-le {
                    .label {
                        font-weight: 600;
                    }

                    .number {
                        color: $provide-teal;
                    }
                }
            }

            .toolbox {
                margin-top: 20px;
                padding: 20px 20px 10px 20px;
                background-color: $main-bg;
                color: $dark-grey;

                .header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $provide-font-family-degular-display;
                    font-weight: 500;
                    font-size: 20px;

                    .icon {
                        margin-right: 10px;
                        font-size: 23px;
                    }
                }

                .item {
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: $provide-font-family-roboto;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .resources {
                margin-top: 20px;
                padding: 20px 20px 10px 20px;
                background-color: $main-bg;
                color: $dark-grey;

                .header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $provide-font-family-degular-display;
                    font-weight: 500;
                    font-size: 20px;

                    .icon {
                        margin-right: 10px;
                        font-size: 23px;
                    }
                }

                .item {
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: $provide-font-family-roboto;

                    a {
                        color: $dark-grey;
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        @media only screen and (max-width: 960px) {
            .right-column {
                margin-top: 20px;
            }
        }

        .status {
            .status-chip {
                height: 6px;
                width: 20px;
                margin-right: 5px;
            }

            .select {
                cursor: pointer;
                font-size: 25px;
                margin-bottom: -3px;
            }

            .status-matched {
                color: $status-matched-color;
            }

            .status-in_progress {
                color: $status-in-progress-color;
                font-weight: 600;
            }

            .status-application {
                color: $status-application-color;
                font-weight: 600;
            }

            .status-valuation {
                color: $status-valuation-color;
                font-weight: 600;
            }

            .status-legals {
                color: $status-legals-color;
                font-weight: 600;
            }

            .status-completed {
                color: $status-completed-color;
                font-weight: 600;
            }

            .status-ended {
                color: $dark-grey;
                font-weight: 600;
            }

            .step-1 {
                background-color: $status-matched-color;
            }

            .step-2 {
                background-color: $status-in-progress-color;
            }

            .step-3 {
                background-color: $status-application-color;
            }

            .step-4 {
                background-color: $status-valuation-color;
            }

            .step-5 {
                background-color: $status-legals-color;
            }

            .step-6 {
                background-color: $status-completed-color;
            }
        }

        .main-le {
            margin-right: 20px;
            padding: 20px;

            .no-favourites {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin: 40px 0px;

                .icon {
                }

                .header {
                    font-weight: 600;
                    color: $dark-grey;
                    font-size: 1.2rem;
                    margin-top: 20px;
                }

                .text {
                    color: $medium-grey;
                }
            }

            .empty-le {
                height: 390px;
                border: 1px dashed $light-grey;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $main-bg;
            }

            .favourited,
            .drafts,
            .recently-updated,
            .recently-added {
                border-radius: 0 !important;

                .header {
                    color: $dark-grey;
                    font-weight: 700;
                    margin-bottom: 0;
                }
            }

            .favourited {
                background-color: $main-bg;
                border-top-left-radius: 4px !important;
                border-top-right-radius: 4px !important;
            }

            .drafts {
                background-color: $main-bg;
            }

            .recently-updated {
                background-color: $main-bg;
            }

            .recently-added {
                background-color: $main-bg;
                border-bottom-left-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
            }

            .le-card {
                height: 390px;
                border: 1px solid $light-grey;
                border-radius: 15px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: $dark-grey;

                .submitted-by {
                    .name {
                        font-size: 16px;
                    }
                }

                .mobile-le-view-btn {
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                .amount {
                    font-size: 30px;
                    font-weight: 700;
                }

                .type {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $provide-font-family-degular-display;
                }

                .matches-count {
                    background-color: $status-matched-color;
                    color: $white;
                    padding: 5px 15px;
                    border-radius: 20px;
                    width: fit-content;
                    width: -moz-fit-content;
                    margin-top: 20px;
                }

                .dates {
                    display: flex;
                    margin-top: 20px;

                    .created {
                        .header {
                            font-weight: 700;
                            size: 11px;
                            margin: 0;
                        }

                        .date {
                            font-size: 16px;
                        }
                    }

                    .updated {
                        .header {
                            font-weight: 700;
                            size: 11px;
                            margin: 0;
                        }

                        .date {
                            font-size: 16px;
                        }
                    }

                    .divider {
                        border: 1px solid $light-grey;
                        margin: 0 20px;
                    }
                }
            }
        }

        .other-le-skeleton {
            border: 1px solid $light-grey;
            border-radius: 4px;
            padding: 20px;
        }

        .other-le {
            cursor: pointer;

            &:hover {
                background-color: $main-bg;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            }
        }

        @media only screen and (max-width: 960px) {
            .main-le {
                margin-right: 0;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                color: $dark-grey;
                font-size: 1rem;
                text-align: unset;
                padding: 0;
                margin: 0;
                width: unset;
            }

            .link {
            }
        }
    }
}
