.text-field {
    flex-wrap: wrap;
    min-width: 50px;
    max-width: 99.9%;
    flex-grow: 1;
    width: 100%;
    cursor: pointer;
    color: $dark-grey;

    input {
        @extend .typography-default;
        height: 44px;
        box-sizing: border-box;
        padding: 12px 15px 12px 20px;

        &:focus {
            background-color: $very-light-grey;
        }
    }

    .MuiOutlinedInput-root {
        background-color: $main-bg;

        &:focus-within {
            background-color: $very-light-grey;
        }
    }

    .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:hover,
    .MuiOutlinedInput-root.Mui-focused,
    .MuiOutlinedInput-root.Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border: 0.5px solid $light-grey;
            border-radius: 2px;
        }
    }

    .MuiFormHelperText-root {
        @extend .typography-field-error;
        margin: 8px 0 0 12px;
    }
    .Mui-error {
        margin-left: 0;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        font-size: 14.666667px;
    }

    .MuiInputLabel-root.Mui-focused {
        color: $dark-grey;
    }

    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}

.form-spacer {
    margin-bottom: 20px;
    .text-field {
        margin-top: 0 !important;
    }
}
