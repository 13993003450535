@import '../base/variables';

.lender-message-modal {
    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        background: $white;
        width: 480px;
        height: 100%;
        padding: 20px 20px 150px 20px;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
                @extend .typography-card-title;
                color: $provide-grey-5;
            }
        }

        .message-input {
            background-color: #f1f1f1;
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .lender-message-modal {
        .MuiPaper-root {
            width: 100%;
        }
    }
}

@media (max-height: 480px) {
    .lender-message-modal {
        .MuiPaper-root {
            .lender-message-logo {
                max-height: 60px;
            }

            .lender-message-text {
                margin-top: 20px;
            }

            .lender-message-button {
                position: relative;
                margin-top: 15px;
                bottom: auto;
                left: auto;
            }
        }
    }
}
