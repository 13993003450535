@import '../base/variables';

.document-sent-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    max-width: 615px;

    .header {
        color: $provide-teal;
        font-size: 2rem;
        text-align: center;
        margin: 30px 0;
    }

    .text {
        text-align: center;
        margin-bottom: 20px;
    }

    .button {
        background-color: $provide-teal;
        color: $white;
        min-width: 150px;

        &:hover {
            background-color: $provide-teal-faded-80;
        }
    }
}
