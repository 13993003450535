@import '../base/variables';

.admin-chase-details-modal-container {
    padding: 40px;

    .header {
        color: $provide-teal;
        font-weight: 700;
        font-size: 18px;
        font-family: 'futura-pt', 'Helvetica', 'Arial', sans-serif;
        margin-bottom: 20px;
    }

    .label {
        color: $dark-grey;
        font-weight: 400;
        font-size: 14px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        margin-bottom: 5px;
    }

    .chase-date-picker {
        margin: 0 0 10px 0;

        .MuiInputBase-root {
            padding: 0;
            width: 100%;
        }
    }

    .chase-note-input {
        margin-top: 20px;

        .MuiInputBase-root {
            width: 100%;
        }
    }

    .actions {
        text-align: right;
        margin-top: 20px;

        .btn-back {
            margin-right: 10px;
        }
    }
}
