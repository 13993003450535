@import '../../base/variables';

.form-message {
    @extend .typography-default;
    align-items: center;
    border-radius: 2px;
    display: flex;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 20px 10px;
    text-align: center;
    align-items: center;
    width: 100%;
    justify-content: center;

    &.form-message-error {
        background: $error-message-background-color;
        color: $red;
    }
}
