.input-file {
    .upload-icon {
        cursor: pointer;
    }

    .MuiFormHelperText-root {
        @extend .typography-field-error;
        margin: 8px 0 0 12px;
    }

    .field-info {
        @extend .typography-small;
        margin: 8px 0;
        color: $dark-grey;
    }

    .MuiInputBase-root.Mui-disabled {
        color: inherit;
    }
}
