@import '../../../base/variables';

.system-events-header-container {
    .header-toolbar {
        font-family: $provide-font-family-roboto;

        label,
        button,
        input {
            font-family: $provide-font-family-roboto;
        }
    }

    .header-toolbar-mobile {
        display: none;
        margin-bottom: 10px;
        font-family: $provide-font-family-roboto;

        label,
        button,
        input {
            font-family: $provide-font-family-roboto;
        }

        .divider {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .container {
            box-shadow: none;
            width: 100%;

            // To remove top border from accordion
            &:before {
                background-color: unset;
            }

            .filter {
                margin-left: 0;
                width: 100%;
            }

            .sort {
                margin-left: 0;
                width: 100%;

                > div {
                    width: 100%;
                }
            }

            .details {
                display: block !important;
                padding: 10px 0;
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .system-events-header-container {
        width: 100%;
        display: block;

        .header-toolbar {
            display: none;
        }

        .header-toolbar-mobile {
            display: block !important;

            .btn-clear-all {
                width: 100%;
            }
        }
    }
}
