@import '../base/variables';

.change-lle-status-container {
    margin: 30px 0 0 30px;

    .change-lle-status-select {
        min-width: 230px;
    }
}

.change-lle-status-popup-container {
    margin: 50px;

    .header {
        margin-bottom: 30px;
        font-size: 1.5rem;
        color: $provide-teal;
        text-align: center;
    }

    .actions {
        text-align: right;

        .btn-yes {
            margin-left: 10px;
            background-color: $provide-teal;
            color: $white;
        }
    }
}
