@import '_variables.scss';

h1 {
    @extend .typography-page-title;
    margin: 0.67em 0;
}

h2 {
    @extend .typography-card-title;
    margin: 0.83em 0;
}

h3 {
    @extend .typography-table-heading;
    margin: 1em 0;
}

h5 {
    @extend .typography-page-title;
    margin: 0.67em 0;
}

.MuiMenuItem-root {
    font-family: $provide-font-family-roboto !important;
}

.MuiSelect-select {
    font-size: 12px !important;
    font-family: $provide-font-family-roboto !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $provide-teal !important;
}

.modal-title {
    color: $provide-teal !important;
    font-family: $provide-font-family-roboto;

    h2 {
        color: $provide-teal !important;
        font-family: $provide-font-family-roboto;
        font-weight: 700;
    }
}
