.business-stream-submit-summary,
.mle-submit-summary {
    color: $dark-grey;

    .submit-summary-heading {
        @extend .typography-card-title;
        @extend .secondary-colour;
        display: inline-block;
        width: 100%;
        margin: 38px 0 40px;
    }

    .submit-summary-subtitle {
        .notice {
            @media (min-width: $breakpoint-md) {
                width: 50%;
            }
        }
    }

    .business-stream-summary-table {
        .summary-table-section:first-child {
            padding-top: 40px;
        }

        .summary-table-section:last-child {
            padding-bottom: 40px;
        }
    }

    .submit-summary-section-name {
        padding: 40px 0;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        width: 100%;

        h2 {
            margin-top: 0;
            margin-bottom: 30px;
        }

        .submit-summary-roundup {
            @extend .typography-default;
            display: inline-block;
            width: 100%;
            line-height: 160%;

            @media (min-width: $breakpoint-md) {
                width: 50%;
            }
        }

        .field-row {
            border: none;

            .container {
                padding: 30px 0 0;

                .text-field {
                    width: 100%;

                    @media (min-width: $breakpoint-md) {
                        width: 40%;
                    }
                }
            }
        }
    }
}
