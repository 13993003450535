@import '../../../base/variables';

.input-autocomplete {
    width: 100%;
    flex-grow: 1;
    position: relative;
    display: inline-block;

    &.input-autocomplete-fullwidth {
        width: 100%;
    }

    .input-text-field-wrapper {
        .input-text-field {
            flex-wrap: wrap;
            min-width: 50px;
            max-width: 99.9%;
            flex-grow: 1;
            width: 100%;
            cursor: pointer;
            color: $dark-grey;
            background-color: $main-bg;

            .MuiOutlinedInput-root,
            .MuiOutlinedInput-notchedOutline {
                border: 0.5px solid $light-grey;
                border-radius: 2px;
            }

            &.input-text-field-disabled {
                padding-right: 22px;
                cursor: not-allowed;
                opacity: 0.5;

                input[disabled] {
                    cursor: not-allowed;
                }
            }

            input {
                @extend .typography-default;

                height: 44px;
                box-sizing: border-box;
                padding: 12px 36px 12px 15px;

                &:focus {
                    background-color: $very-light-grey;
                }
            }
        }

        .MuiTextField-root {
            width: 100%;
        }

        .arrow-dropdown {
            position: absolute;
            right: 12px;
            top: 14px;
            cursor: pointer;
        }

        .clear-icon {
            position: absolute;
            width: 16px;
            right: 32px;
            color: $provide-teal;
            top: 14px;
            cursor: pointer;
        }
    }

    .MuiFormHelperText-root {
        @extend .typography-field-error;
        margin: 8px 0 0 12px;
    }
}

.input-dropdown-wrapper {
    z-index: 1300; // Must be at least equal to the z-index of the modals

    .input-dropdown {
        margin-top: 8px;

        .input-dropdown-scrollable-items {
            overflow-y: scroll;

            .input-dropdown-item-group {
                font-weight: 400;
                padding-left: 24px;
            }

            .input-dropdown-item {
                @extend .typography-default;
                color: $dark-grey;
                font-weight: 400;

                &.input-dropdown-item-selected {
                    background: lighten($red, 30);
                }

                .input-dropdown-item-label {
                    white-space: normal;

                    .input-dropdown-item-label-highlight {
                        font-weight: 600;
                        color: $red;
                    }
                }
            }
        }
    }
}
