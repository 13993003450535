@import '../../base/variables';

.terms-main-interest-rate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    margin: 10px 0 10px 0;

    .rate-container {
        .header {
            color: $provide-black;
            font-family: $provide-font-family-roboto;
            font-size: 13px;
            font-weight: 400;
        }

        .rate {
            color: $provide-teal;
            font-size: 18px;
            font-weight: 500;
            font-family: $provide-font-family-degular-display;
        }
    }

    .awaiting-lender-container {
        display: flex;
        background-color: $white;
        background-image: $provide-linear-gradient-green;
        border: 2px solid $black;
        color: $provide-black;
        border-radius: 40px;
        padding: 15px;
        align-items: center;

        .header {
            display: flex;
            align-items: center;
            font-size: 18px;
            padding: 0 20px 0 0;
            border-right: 1px solid $white;

            .icon {
                font-size: 20px;
                margin-right: 5px;
            }
        }

        .date-container {
            padding: 0 0 0 20px;

            .date {
                font-weight: 600;
                font-size: 13px;
            }
        }
    }
}

.top-terms-container {
    margin: 40px 30px 20px 30px;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    padding: 15px;
    background-color: $main-bg;

    .term-item {
        .name {
            color: $provide-black;
        }

        .value {
            color: $provide-teal;
            font-size: 16px;

            &.empty {
                color: $light-grey;
                font-size: 16px;
            }
        }
    }
}

.detail-terms-container {
    padding: 0 30px;
    margin: 20px 0 0 0;

    .divider {
        margin: 30px 0 30px 0;
        border-bottom: 1px solid $light-grey;
    }

    .term-container {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-bottom: 10px;
        justify-content: space-between;
        font-weight: 600;

        .header {
            display: flex;
            align-items: center;
            color: $provide-black;
            font-size: 14px;

            .icon {
                color: $light-grey;
                font-size: 2rem;
                margin: 0 10px 0 0;
            }
        }

        .value {
            color: $provide-teal;
            font-size: 16px;
            font-weight: 400;

            &.empty {
                color: $light-grey;
            }
        }
    }
}

.fee-term-container {
    margin-bottom: 190px;

    .header {
        font-size: 20px;
        color: $provide-black;
        font-family: $provide-font-family-degular-display;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .group-container {
        padding-left: 7px;

        .item {
            display: flex;
            align-items: center;
            padding: 5px 0;
            color: $provide-black;
            font-weight: 500;
            font-size: 14px;
            font-family: $provide-font-family-roboto;

            .icon {
                margin-right: 5px;
                color: $light-grey;
                width: 12px;
            }

            .value {
                color: $provide-teal;
                padding-left: 5px;
                font-size: 16px;

                &.pending {
                    color: $light-grey;
                }
            }
        }
    }
}

.start-application-container,
.request-lender-container {
    background-color: $white;
    padding: 30px;
    border-radius: 10px;
    margin: 30px 0;
    position: fixed;
    bottom: 0;
    right: 230px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    .header {
        font-family: $provide-font-family-degular-display;
        font-size: 25px;
        color: $provide-black;
        margin-bottom: 10px;
        font-weight: 500;
    }

    .btn {
        background-color: $provide-teal;
        color: $white;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        font-weight: 700;
        width: 100%;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            background-color: $provide-teal-faded-80;
        }
    }
}

.start-application-container {
    .btn {
        background-color: $red;

        &:hover {
            background-color: $red-faded-90;
        }
    }
}

.detail-terms-updated-at-container {
    display: flex;
    justify-content: flex-end;

    .box {
        background-color: $very-light-grey;
        color: $provide-black;
        border-radius: 5px;
        padding: 20px;
        height: 80px;
    }

    .date {
        font-weight: 600;
    }
}

@media only screen and (max-width: 960px) {
    .request-lender-container {
        right: 30px;
    }
}
