@import '../../../base/variables';

.borrower-view-lead {
    img.favourite {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    .view-lead-header {
        margin-bottom: 0;

        .compare-icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            color: $red;
            margin: 0 10px;
            background: url(../images/compare_arrows.svg) transparent no-repeat;
            position: relative;
            top: 10px;

            &.compare-icon-disabled {
                filter: none;
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
            }
        }
    }
}
