@import '../../base/variables';

.tag-properties-container {
    padding: 40px;

    .header {
        color: $provide-teal;
        margin-top: 0 !important;
        margin-bottom: 30px !important;
    }

    .tag-label {
        margin-bottom: 20px;

        .MuiInputBase-root {
            width: 100%;
        }
    }

    .color-picker-container {
        margin: 0 0;
        display: block;
        height: 0;
        opacity: 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        transition: all 0.5s ease;

        &.visible {
            height: 280px;
            opacity: 1;
        }
    }

    .more-colors-container {
        justify-content: center;
        display: flex;
        margin-bottom: 20px;

        .link-more-colors {
            margin-bottom: 20px;
            cursor: pointer;
            z-index: 1;

            &:hover {
                color: $provide-teal;
                transition: all 0.2s;
            }
        }
    }

    .color-picker {
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 9px;
    }

    .color-value {
        margin-bottom: 20px;
        border-radius: 4px;
        text-align: center;
        padding: 10px 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .actions {
        width: 100%;
        text-align: center;

        .btn-cancel {
            margin-right: 10px;
        }
    }

    .picker__swatches {
        display: flex;
        padding: 0 0 20px 0;
        flex-wrap: wrap;
        max-width: 190px;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .picker__swatch {
        width: 24px;
        height: 24px;
        margin: 4px;
        border: none;
        padding: 0;
        border-radius: 4px;
        cursor: pointer;
        outline: none;

        &.active {
            //border: 4px solid $light-grey;
            border: 3px solid $black;
            //box-shadow: 0 0 10px 2px $dark-grey;
        }
    }
}

.table-tags-container {
    display: flex;
    overflow-x: auto;

    .table-tag-item {
        color: rgb(255, 255, 255);
        padding: 2px 10px;
        border-radius: 4px;
        text-align: center;
        margin-left: 5px;
        font-size: 10px;

        &:first-child {
            margin-left: 0;
        }
    }
}
