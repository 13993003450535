.placeholder-image-layout {
    width: 100%;
    min-height: 250px;
    text-align: center;

    &.chat-placeholder {
        align-items: flex-start;
        justify-content: center;
        min-height: 350px;
        background: url(../images/chat-placeholder.svg) transparent no-repeat 50% 30%;

        &.chat-placeholder-disabled {
            filter: none;
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
        }

        .placeholder-image-content {
            text-align: center;
            max-width: 280px;
            padding-top: 220px;
        }
    }

    &.business-stream-table-placeholder {
        align-items: flex-end;
        justify-content: center;
        background: url(../images/empty-table-placeholder.svg) transparent no-repeat 50% 40%;
        min-height: 350px;

        .placeholder-image-content {
            padding-bottom: 60px;
        }
    }

    &.empty-table-placeholder {
        align-items: flex-start;
        justify-content: center;
        background: url(../images/empty-table-placeholder.svg) transparent no-repeat 50% 30%;
        min-height: 487px;

        .placeholder-image-content {
            text-align: center;
            max-width: 280px;
            padding-top: 250px;
        }
    }

    &.unverified-user-empty-table-placeholder {
        align-items: flex-start;
        justify-content: center;
        background: url(../images/unverified-icon.svg) transparent no-repeat 50% 30%;
        min-height: 487px;

        .placeholder-image-content {
            text-align: center;
            max-width: 280px;
            padding-top: 250px;
        }
    }

    &.document-list-placeholder {
        align-items: flex-start;
        justify-content: center;
        background: url(../images/empty-table-placeholder.svg) transparent no-repeat 50% 30%;
        min-height: 487px;

        &.document-list-placeholder-disabled {
            filter: none;
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
        }

        .placeholder-image-content {
            text-align: center;
            max-width: 280px;
            padding-top: 250px;
        }
    }
}
