@import '../base/variables';

.primary-button,
.secondary-button,
.tertiary-button {
    &.MuiButtonBase-root {
        box-shadow: none;

        &:active {
            box-shadow: none;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

.primary-button {
    &.MuiButtonBase-root {
        @extend .typography-table-heading;

        width: auto;
        padding: 10px 35px;
        border-radius: 3px;

        &.MuiButton-containedPrimary {
            background: $provide-teal;
            color: white;
            margin-right: 20px;

            &:hover {
                background: lighten($provide-grey-5, 10);
            }

            &:active {
                background: $provide-teal;
            }
        }

        &.MuiButton-containedSecondary {
            background: $provide-teal;
            color: white;

            &:hover {
                background: darken($provide-teal, 10);
            }

            &:active {
                background: $provide-grey-5;
            }
        }
    }
}

.secondary-button {
    &.MuiButtonBase-root {
        @extend .typography-table-heading;

        width: auto;
        padding: 10px 35px;
        margin-right: 7px;
        border-radius: 0;
        border: none;
        background: none;
        color: $provide-grey-5;

        &:hover {
            background-color: $very-light-grey;
        }

        &:active {
            background: $table-bg;
        }
    }
}

.tertiary-button {
    &.MuiButtonBase-root {
        @extend .typography-table-heading;

        width: auto;
        padding: 10px 35px;
        border-radius: 0;
        border: none;
        background-color: $supporting-blue-medium;
        color: $white;

        &:hover {
            background-color: $half-baked;
        }

        &:active {
            background: $table-bg;
        }
    }
}

.primary-button:disabled,
.secondary-button:disabled {
    background-color: $button-disabled !important;
}

.switch-button {
    .switch-button-toggle {
        color: $darker-grey;

        &:hover {
            cursor: pointer;
        }

        &:hover,
        &:active {
            color: black;
        }
    }
}

.switch-button-dropdown {
    min-width: 150px;
    max-width: 270px;
    border-radius: 0 0 $menu-border-radius $menu-border-radius;
    z-index: 1101; // Must be at least as high as the AppBar (1100)

    .MuiList-root {
        .MuiListItem-root {
            @extend .typography-default;

            padding-left: $menu-gutter;
            padding-right: $menu-gutter;
        }
    }
}

.MuiButton-root {
    border-radius: 15px !important;
    font-family: $provide-font-family-roboto !important;
}

.MuiButton-containedPrimary {
    background-color: $provide-teal !important;
}

.MuiButton-contained {
    box-shadow: none !important;
}
