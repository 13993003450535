.notice {
    @extend .typography-default;
    color: $dark-grey;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: $info-box-bg;
    border-radius: 4px;
    line-height: 160%;
    position: relative;

    &-success {
        background-color: $snackbar-green;
        color: white;
    }

    &-error {
        background-color: $snackbar-red;
        color: white;
    }

    &-info {
        background-color: $red;
        color: white;
    }

    &-warning {
        background-color: $snackbar-orange;
        color: white;
    }

    .notice-icon {
        .MuiSvgIcon-root {
            width: 24px;
            height: 24px;
            margin-right: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        @media (max-width: $breakpoint-sm) {
            display: none;
        }
    }
}
