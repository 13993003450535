@import '_variables.scss';

html,
body {
    background-color: $white;
    margin: 0;
    padding: 0;
    min-width: 320px;
}

.MuiButton-root {
    &.Mui-disabled {
        background-color: $light-grey !important;
    }
}
