.field-button-panel-row {
    padding-top: 40px;

    .field-button-panel-row-item {
        display: inline-block;
        margin-right: 20px;

        &:empty {
            margin: 0;
        }
    }
}
.draft-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .draft-button {
        margin: 0 20px;
    }
}
