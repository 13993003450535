.switch {
    .MuiSwitch-switchBase {
        color: $very-light-grey;

        &.Mui-checked {
            color: $light-grey;

            & + .MuiSwitch-track {
                background-color: $light-grey;
            }
        }
    }

    .MuiSwitch-colorPrimary {
        &.Mui-checked {
            color: $provide-grey-5;

            & + .MuiSwitch-track {
                background-color: $provide-grey-5;
            }

            &:hover {
                background-color: transparentize($provide-grey-5, 0.9);
            }
        }
    }
}
