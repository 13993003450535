@import '../base/variables';

.MuiSwitch-root {
    .MuiSwitch-switchBase {
        color: $white;
    }

    .MuiSwitch-colorPrimary.Mui-disabled {
        color: $white;
    }

    .MuiSwitch-colorPrimary.Mui-checked {
        color: $provide-black;
    }

    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: $provide-grey-5;
    }

    .MuiSwitch-track {
        background-color: $provide-grey-5;
        opacity: 0.5;
    }

    .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
        background-color: $provide-grey-5;
        opacity: 0.5;
    }
}
