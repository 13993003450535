.business-stream-view {
    .business-stream-view-header {
        margin-top: 0;
        .button-toggle-status {
            margin-right: 20px;
        }
    }

    .business-stream-view-content {
        margin-top: 40px;
    }

    .business-stream-summary-table {
        .summary-table-section {
            border-bottom: none;
        }
    }
}
