.splash {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9;
    overflow-y: hidden;
    padding: 20px;

    @media (min-width: $breakpoint-sm) {
        display: none;
    }

    &-outer {
        background-color: $main-bg;
        height: 100%;
    }

    &-inner {
        .heading {
            @extend .typography-card-title-brand-blue;
        }

        .subtitle {
            @extend .typography-card-subtitle;
            line-height: 147.3%;
            letter-spacing: 0.0357143em;
            color: $dark-grey;
        }

        .close-button {
            padding: 0;
            margin: 15px 20px 15px 0;
            .MuiSvgIcon-root {
                font-size: 25px;
                color: $very-dark-grey;
            }
        }
    }
}
