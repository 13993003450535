@import '../../base/variables';

.loan-enquiry-context-menu {
    border: 1px solid $light-grey;
    background: $white;
    border-radius: 20px;
    position: relative;
    height: 39px;
    width: 32px;
    padding-top: 23px;
    padding-left: 15px;
}
