.leads-container {
    margin-left: -$p4style-container-padding-horiz;
    margin-right: -$p4style-container-padding-horiz;

    .table-head {
        th {
            color: $darkest-grey;
            border-bottom: none;
        }

        th:first-child {
            padding-left: $p4style-container-padding-horiz;
        }

        th:last-child {
            padding-right: $p4style-container-padding-horiz;
        }
    }

    .table-row {
        td:first-child {
            padding-left: $p4style-container-padding-horiz;
        }

        td:last-child {
            padding-right: $p4style-container-padding-horiz;
        }
    }

    .matching-information {
        @extend .typography-card-subtitle;
        margin-top: 20px;
        text-align: center;
        .total-matches {
            font-size: 18px;
        }
    }
}
