@import '../base/variables';

.MuiPaper-rounded {
    border-radius: 20px !important;
}

.MuiFormControl-root {
    border-radius: 20px !important;
}

.MuiPickersModal-dialogRoot .MuiPickersDay-day.MuiPickersDay-daySelected {
    background-color: $provide-black !important;
}

.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar {
    background-color: $provide-black !important;
}

.MuiOutlinedInput-root {
    border-radius: 20px !important;
    background-color: #ffffff !important;
}

.MuiSelect-root {
    border-radius: 20px !important;
    background-color: #ffffff !important;
}

.MuiButton-root {
    border-radius: 20px !important;
}

.MuiFormLabel-root {
    font-family: $provide-font-family-roboto !important;
}

.MuiFormHelperText-root {
    &.Mui-error {
        font-family: $provide-font-family-roboto !important;
    }
}

.MuiInputBase-input {
    background-color: #ffffff !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-radius: 20px !important;
}
