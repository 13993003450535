@import '../base/variables';

.tooltip-anchor {
    width: 20px;
    height: 20px;
    display: inline-block;

    .tooltip-icon {
        color: $provide-teal;
        cursor: pointer;
    }
}

.joyride-tooltip-width {
    max-width: 490px !important;
}

.tooltip {
    @extend .typography-tooltip;
    padding: 25px;
    border-radius: 3px;
    max-width: 350px;
    line-height: 20px;
    color: $dark-grey;

    &.MuiPaper-root {
        color: $dark-grey;
    }

    .tooltip-confirm {
        display: inline-block;
        color: $provide-teal;
        cursor: pointer;
        margin-top: 10px;
    }

    .token-route-change {
        color: $snackbar-red;
    }

    .token-link {
        color: $supporting-cyan;
        text-decoration: underline;
    }

    .token-highlight {
        color: $supporting-blue-medium;
        font-weight: 400;
    }
}

[role='tooltip'] {
    &[x-placement*='bottom'] {
        .tooltip {
            margin-top: 5px;

            &.tooltip-with-arrow {
                margin-top: 20px;

                :after,
                :before {
                    background-color: $white;
                    content: '';
                    height: 24px;
                    top: -12px;
                    margin-top: 20px;
                    position: absolute;
                    left: 50%;
                    transform: rotate(45deg) translateX(-8px) translateY(8px);
                    width: 24px;
                    z-index: 0;
                }

                :before {
                    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.06),
                        0 1px 18px 0 rgba(0, 0, 0, 0.06);
                    z-index: -1;
                }
            }
        }
    }

    &[x-placement*='top'] {
        .tooltip {
            margin-bottom: 5px;

            &.tooltip-with-arrow {
                margin-bottom: 20px;

                :after,
                :before {
                    background-color: $white;
                    content: '';
                    height: 24px;
                    bottom: -12px;
                    margin-bottom: 20px;
                    position: absolute;
                    left: 50%;
                    transform: rotate(45deg) translateX(-8px) translateY(8px);
                    width: 24px;
                    z-index: 0;
                }

                :before {
                    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.06),
                        0 1px 18px 0 rgba(0, 0, 0, 0.06);
                    z-index: -1;
                }
            }
        }
    }

    &[x-placement*='right'] {
        .tooltip {
            margin-left: 5px;

            &.tooltip-with-arrow {
                margin-left: 20px;

                :after,
                :before {
                    background-color: $white;
                    content: '';
                    height: 24px;
                    left: -12px;
                    margin-left: 20px;
                    position: absolute;
                    top: 50%;
                    transform: rotate(45deg) translateX(-8px) translateY(-8px);
                    width: 24px;
                    z-index: 0;
                }

                :before {
                    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.06),
                        0 1px 18px 0 rgba(0, 0, 0, 0.06);
                    z-index: -1;
                }
            }
        }
    }

    &[x-placement*='left'] {
        .tooltip {
            margin-right: 5px;

            &.tooltip-with-arrow {
                margin-right: 20px;

                :after,
                :before {
                    background-color: $white;
                    content: '';
                    height: 24px;
                    right: -12px;
                    margin-right: 20px;
                    position: absolute;
                    top: 50%;
                    transform: rotate(45deg) translateX(-8px) translateY(-8px);
                    width: 24px;
                    z-index: 0;
                }

                :before {
                    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.06),
                        0 1px 18px 0 rgba(0, 0, 0, 0.15);
                    z-index: -1;
                }
            }
        }
    }
}
