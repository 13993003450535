/* Colours */

/* Provide brand*/
$provide-teal: #2ec4b6;
$provide-green: #234c3c;
$provide-teal-faded-80: rgba(46, 196, 182, 0.8);
$provide-teal-faded-20: rgba(46, 196, 182, 0.2);
$provide-white: rgba(255, 255, 255, 1);
$provide-white-faded-70: rgba(255, 255, 255, 0.7);
$provide-pink: #ff007a;
$provide-light-pink: #c3a1d6;

$provide-linear-gradient-green: linear-gradient(to right, rgba(187, 186, 232, 0.2), rgba(46, 196, 182, 0.2));
$provide-linear-gradient-pink: linear-gradient(to right, $provide-light-pink, $provide-pink);

$provide-admin-dashboard-unassigned-color: #2ec4b6;
$provide-admin-dashboard-unassigned-color-faded-20: rgba(46, 196, 182, 0.2);
$provide-admin-dashboard-my-assigned-color: #bbbae8;
$provide-admin-dashboard-my-assigned-color-faded-20: rgba(187, 186, 232, 0.2);
$provide-admin-dashboard-my-favourite-color: #ffc400;
$provide-admin-dashboard-my-favourite-color-faded-20: rgba(255, 196, 0, 0.2);
$provide-admin-dashboard-enq-with-updates-color: #7eb5d8;
$provide-admin-dashboard-enq-with-updates-color-faded-20: rgba(126, 181, 216, 0.2);
$provide-admin-dashboard-new-borrower-accounts-color: #8594c3;
$provide-admin-dashboard-new-borrower-accounts-color-faded-20: rgba(133, 148, 195, 0.2);

$provide-slam-dashboard-new-leads-color: #2ec4b6;
$provide-slam-dashboard-leads-in-progress-color: #bbbae8;
$provide-slam-dashboard-leads-in-application-color: #7eb5d8;

$provide-black: #0d0a24;
$provide-purple: #bbbae8;
$provide-grey-10: #e9e9e9;
$provide-grey-9: #969696;
$provide-grey-8: #f1f1f1;
$provide-grey-7: #344756;
$provide-grey-6: #606060;
$provide-grey-5: #5a6f7e;
$provide-grey-4: #7b8c98;
$provide-grey-3: #9ca9b2;
$provide-grey-2: #bdc5cb;
$provide-grey-1: #dee2e5;
$provide-supporting-cyan: #00bbc0;

$provide-borrower-color: #ff0090;
$provide-lender-color: #2ec4b6;
$provide-intermediary-color: #0e79b2;
$provide-admin-color: #5a6f7e;
$provide-super-admin-color: #5a6f7e;

$provide-font-family-arial: 'Arial', 'Roboto', 'Helvetica', sans-serif;
$provide-font-family-roboto: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$provide-font-family-degular-display: 'degular-display', sans-serif;
//$provide-font-family-futura-pt: 'degular-display', 'Helvetica', 'Arial', sans-serif; //- change to the new font
//$provide-font-family-degular-display: $provide-font-family-degular-display;
//$provide-font-family-proxima-nova: 'proxima-nova', 'Helvetica', 'Arial', sans-serif; //- change to the new font
//$provide-font-family-degular-display: $provide-font-family-degular-display;
/* End Provide brand*/

$dark-blue: #5c6bc0;

$error-message-background-color: rgba(255, 23, 68, 0.1);
$form-error: #f44336;
$green: #26a69a;
$normal-green: #008000;
$header-bg: #ffffff;
$header-border-color: #aeaeae;
$info-box-bg: #f0f0f0;
$light-font: white;

$main-bg: #fafafa;

$pink-dusky: #f9c6d7;
$pink: #d500f9;
$purple: #ab47bc;
$red: #ec407a;
$red-faded-90: rgba(236, 64, 122, 0.9);
$coral-faded-20: rgba(236, 64, 122, 0.2);
$royal-blue: #2979ff;
$medium-blue: #8594c3;
$medium-blue-faded-20: rgba(133, 148, 195, 0.2);

$supporting-blue-medium: #96c4d6;
$supporting-blue-light: #8594c3;
$half-baked: #82b9ce;
$supporting-cyan: #10cac8;
$supporting-cyan-faded-20: rgba(16, 202, 200, 0.2);
$supporting-cyan-faded-90: rgba(16, 202, 200, 0.9);
$supporting-pink: #ff0090;
$table-bg: white;
$table-controls-icon-hover: black;
$very-dark-blue: #0c0a3e;
$very-dark-blue-faded-20: rgba(12, 10, 62, 0.2);

$very-dark-grey: #5c5c5b;
$dark-grey: #606060;
$darker-grey: #757575;
$darkest-grey: #303030;
$light-grey: #c7c7c7;
$light-grey-1: #c4c4c4;
$ultra-light-grey: #f0f0f0;
$medium-grey: #888888;
$pale-grey: #ccd7e5;
$medium-light-grey: #dfdfdf;
$very-light-grey: #f1f3f4;
$dark-grey-faded-20: rgba(96, 96, 96, 0.2);

$white: white;
$black: black;
$yellow: #ffc400;
$yellow-faded-20: rgba(255, 196, 0, 0.2);

// admin dashboard chart - keep them for reference only. Colours are set directly in the chart object options in
// src/apps/admin/pitch4admin/dashboard/components/chart/ChartContainer.js
//$blue: rgba(12, 10, 62, 1);
//$red: rgba(236, 64, 122, 1);

// snackbar colours - not sure if these will be reused or not
$snackbar-green: #00e676;
$snackbar-red: #ff1744;
$snackbar-orange: #ffc400;

//button specific
$button-disabled: #d2d2d2;

/* Margin / padding */
$content-gutter: 30px;
$menu-border-radius: 4px;
$menu-gutter: 20px;
$p4style-container-padding-vert: 40px;
$p4style-container-padding-horiz: 36px;

/* Breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1960px;

/* Status colors */
$status-step-incomplete-color: $light-grey;
$status-matched-color: #bbbae8;
$status-in-progress-color: #9db2e0;
$status-application-color: #7eb5d8;
$status-valuation-color: #54b9cc;
$status-legals-color: #40b9c8;
$status-completed-color: #2ec4b6;
$status-withdrawn-color: #2ec4b6;
$status-completion-requested-color: $status-completed-color;
$status-completion-rejected-color: $status-completed-color;
$status-lender-declined-color: $dark-grey;
