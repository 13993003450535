@import '../base/variables';

.authentication {
    background-color: #ffffff;
    mix-blend-mode: multiply;
    display: flex;
    align-items: center !important;
    justify-content: center;
    height: 100vh;

    .container {
        width: 100%;
        max-width: 500px;
        align-self: center;
        margin: 20px;

        .branding-container {
            margin-bottom: 20px;

            .logo {
                max-width: 240px;
                min-width: 250px;
            }
        }

        .registration-branding-container {
            margin-bottom: 1.7rem;
        }

        .content-container {
            width: 100%;
            background-color: white;
            padding: 40px;

            .body {
                @extend .typography-authentication-body;
                line-height: 24px;
            }

            .form-container {
                margin-bottom: 30px;
            }

            .form-messages {
                margin-bottom: 30px;
            }

            .heading {
                @extend .typography-card-title-brand-blue;
                margin: 0;
            }

            .button-panel {
                margin-top: 41px;
            }

            .login .button-panel {
                margin-top: 16px;
            }

            .text-field,
            .input-autocomplete {
                margin-top: 31px;
            }

            .forgot-password {
                @extend .typography-small;
                margin-top: 17px;
            }
        }

        .footer-container {
            @extend .typography-small;
            color: $dark-grey;
            margin-top: 20px;

            & a {
                color: $dark-grey;
                text-decoration: none;
            }

            .links {
                margin-top: 44px;
                line-height: 18px;

                & a {
                    margin: 0 8px;
                }
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .authentication {
        background-image: none;
        align-items: flex-start !important;

        .container {
            margin: 20px;
            align-self: auto;

            .branding-container {
                margin-bottom: 30px;
            }

            .content-container {
                padding: 40px 30px;

                .form-container {
                    margin-bottom: 20px;
                }

                .form-messages {
                    margin-bottom: 20px;
                }

                .button-panel {
                    margin-top: 16px;
                }

                .text-field,
                .input-autocomplete {
                    margin-top: 20px;
                }
            }

            .footer-container {
                margin-top: 15px;
            }
        }
    }
}
