@import '../../base/variables';

.card-view-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 30px;
    padding-bottom: 20px;

    .lender-card {
        border-radius: 20px;
        height: 135px;
        width: 222px;
        border: 1px solid $light-grey;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            background-color: $main-bg;
        }

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            cursor: pointer;
            margin-bottom: 5px;

            .lender-name {
                display: flex;
                flex-direction: column;
                line-height: normal;
                color: $provide-pink;

                .anon-name {
                    color: #888888;
                    font-size: 12px;
                }
            }
        }

        .interest-rate-container {
            margin-top: 15px;

            .rate {
                color: $provide-teal;
                font-weight: 600;
            }
        }

        .footer {
            .select {
                display: flex;
                align-items: center;
            }

            .status {
                .status-chip {
                    height: 6px;
                    width: 20px;
                    margin-right: 5px;
                }

                .select {
                    cursor: pointer;
                    font-size: 25px;
                    margin-bottom: -3px;
                }

                .status-matched,
                .status-in_progress,
                .status-application,
                .status-valuation,
                .status-legals,
                .status-completed,
                .status-completion_requested,
                .status-completion_rejected,
                .status-ended {
                    font-size: 13px;
                    font-weight: 700;
                    color: $dark-grey;
                }

                .step-1 {
                    background-color: $status-matched-color;
                }

                .step-2 {
                    background-color: $status-in-progress-color;
                }

                .step-3 {
                    background-color: $status-application-color;
                }

                .step-4 {
                    background-color: $status-valuation-color;
                }

                .step-5 {
                    background-color: $status-legals-color;
                }

                .step-6 {
                    background-color: $status-completed-color;
                }
            }
        }

        .favourite-icon {
            cursor: pointer;
        }

        &.favourite {
            background-color: $main-bg;
            border: 1px solid $light-grey;
        }
    }
}
