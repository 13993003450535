@import '../base/variables';

tr.alert {
    background-color: #f3f3f3;
    border-bottom: 5px solid #ffffff;
}

td.alert {
    text-align: right;
}

.alert {
    &.dot-indicator-active {
        color: $provide-teal;
        position: relative;
        top: -8px;
        left: 5px;
    }

    .active {
        color: $red;
    }
}
