@import '../../base/variables';

.progress-bar-container {
    display: flex;
    justify-content: space-between;

    .context-menu {
        width: auto;
        border: 1px solid #c7c7c7;
        box-shadow: none;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .favourite-container {
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        width: 45px;
        height: 45px;
        justify-content: center;
    }

    .buttons-container {
        display: flex;
        justify-content: flex-end;

        .MuiButton-label {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .progress-container {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: transparent;
        box-shadow: none;
        border: none;
        justify-content: space-between;
        width: -webkit-fill-available;
        padding: 0 20px 0 0;
        margin: 0 20px 0 0;
        max-width: 1000px;

        .sub-title {
            white-space: nowrap;
            font-weight: 700;
            color: $provide-black;
        }

        .status-container {
            display: flex;
            align-items: center;
            height: 100%;
            flex: 0 25%;
            justify-content: center;

            .status-label {
                border-radius: 20px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.inactive {
                    font-weight: 700;
                    color: $light-grey;
                }

                &.active {
                    background-color: #561c52;
                    padding: 0 15px;
                    font-weight: 700;
                    color: $white;
                    font-size: 14px;

                    &.status-matched {
                        background-color: $status-matched-color;
                    }

                    &.status-in_progress {
                        background-color: $status-in-progress-color;
                    }

                    &.status-application {
                        background-color: $status-application-color;
                    }

                    &.status-valuation {
                        background-color: $status-valuation-color;
                    }

                    &.status-legals {
                        background-color: $status-legals-color;
                    }

                    &.status-completed {
                        background-color: $status-completed-color;
                    }

                    &.status-withdrawn {
                        background-color: $status-completed-color;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .progress-bar-container {
        .buttons-container {
            justify-content: flex-start;
        }
    }
}

@media only screen and (max-width: 523px) {
    .progress-bar-container {
        .buttons-container {
            .context-menu {
                margin-top: 20px;
            }
        }
    }
}

@media only screen and (max-width: 429px) {
    .progress-bar-container {
        .buttons-container {
            .btn-send-to-selected {
                margin-top: 20px;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .progress-bar-container {
        display: block;
    }
}

@media only screen and (max-width: 1050px) {
    .progress-bar-container {
        .progress-container {
            display: block;
            width: auto;
            height: auto;

            .status-container {
                height: auto;
                justify-content: flex-start;
            }
        }

        .favourite-container {
            margin-top: 15px;
        }

        .buttons-container {
            margin-top: 15px;
        }

        .favourite-icon {
            display: none;
        }
    }
}
