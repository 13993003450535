@import '../base/variables';

.table-container {
    background: inherit;
    border: 1px solid $very-light-grey;

    .MuiTableCell-root {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: $dark-grey;
        border-bottom-color: $very-light-grey;
    }

    .table-pagination {
        .MuiTypography-body2 {
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: 14px !important;
            color: $dark-grey;
        }
    }

    .table-head {
    }

    .table-body {
    }

    .table-row {
    }
}

.table-wrapper {
    background: $table-bg;
    overflow-x: scroll;

    &.full-width-table {
        .table-head {
            .MuiTableCell-head {
                padding: 28px 0px 15px;
            }
        }

        .MuiTableCell-root {
            padding: 12px 0px 12px;
        }

        &.table-empty {
            .MuiTableCell-body {
                padding: 25px 0px;
            }
        }
    }

    .MuiTableCell-root {
        padding: 12px 25px 12px;
    }

    .table-head {
        .MuiTableCell-head {
            @extend .typography-table-heading;
            padding: 28px 25px 15px;
        }
    }

    .table-body {
        .MuiTableCell-body {
            @extend .typography-table-body;
            border: none;
        }

        .MuiTableRow-root {
            transition: background-color 0.5s ease;

            &:hover {
                background-color: $very-light-grey;

                &.table-row-link {
                    cursor: pointer;
                }

                .table-row-controls {
                    opacity: 1;
                }
            }

            &.table-row-highlight {
                .MuiTableCell-body {
                    color: $red;

                    a {
                        color: $red;

                        .primary-colour {
                            color: $red;
                        }
                    }

                    .MuiGrid-item {
                        color: $darkest-grey;
                    }
                }
            }

            &.no-hover:hover {
                background-color: transparent;
            }

            .table-row-controls {
                opacity: 0;
                transition: opacity 0.5s ease;

                .actions {
                    display: inline-block;
                    float: right;
                }

                .MuiSvgIcon-root {
                    color: $darker-grey;
                    float: right;
                    position: relative;
                    top: 2px;

                    &.view-icon {
                        width: 25px;
                        height: 25px;

                        @media (max-width: $breakpoint-md) {
                            display: none;
                        }
                    }

                    &.switch-button-toggle {
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;

                        &:hover {
                            color: $table-controls-icon-hover;
                        }
                    }
                }
            }
        }

        &.table-empty {
            .MuiTableCell-body {
                padding: 25px;
            }
        }
    }

    .table-footer {
        .MuiTablePagination-root {
            padding: 0;

            .MuiTablePagination-caption,
            .MuiInputBase-input,
            button {
                @extend .typography-pagination;
            }
        }
    }
}

.table-controls {
    padding-bottom: 10px;
}

.table-container {
    .MuiGrid-item {
        margin-top: 19px;
        vertical-align: middle;
        height: 30px;
        line-height: 30px;
    }

    .MuiGrid-item {
        line-height: normal;
        height: 100%;

        .key-value-term {
            @extend .typography-default;
        }

        .key-value-definition {
            @extend .typography-card-data;
            white-space: pre-line;
        }
    }
}
