@import '../../base/variables';

.application-started-modal {
    padding: 50px;
    max-width: 600px;

    .header {
        font-family: 'Roboto', sans-serif !important;
        font-size: 30px;
        font-weight: 400;
        line-height: 35px;
        text-align: center;
        color: $provide-teal;
        margin-bottom: 30px;
    }

    .text {
        font-family: 'Roboto', sans-serif !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        margin-bottom: 30px;
        color: $provide-black;

        .bold {
            font-weight: 700;
        }
    }

    .actions {
        text-align: center;
    }
}
