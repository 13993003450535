@import '../base/variables';

.lender-profile-modal {
    .lender-drawer-profile-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px 20px 30px;
        height: 100px;
        background-color: $white;
        color: $provide-grey-6;
    }

    .match-status {
        font-family: $provide-font-family-roboto;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        padding: 30px 50px;
        border-radius: 40px;

        &.matched {
            background-color: $status-matched-color;
        }

        &.in_progress {
            background-color: $status-in-progress-color;
        }

        &.application {
            background-color: $status-application-color;
        }

        &.valuation {
            background-color: $status-valuation-color;
        }

        &.legals {
            background-color: $status-legals-color;
        }

        &.completed {
            background-color: $status-completed-color;
        }
    }

    .lender-profile-image {
        padding: 0 30px;
        background-color: $white;
    }

    .MuiAccordion-root {
        position: unset;
    }

    .lender-description-accordion {
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        width: 100% !important;

        .MuiAccordionSummary-root {
            padding: 0;
            font-weight: 600;
        }

        .MuiAccordionDetails-root {
            padding: 0;
        }
    }

    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        background: $white;
        width: 680px;
        height: 100%;
        padding: 0;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);

        .lender-profile-logo {
            max-height: 100px;
        }

        .lender-profile-title {
            .MuiTypography-root {
                @extend .typography-card-title;
                color: $red;
            }

            margin: 0 0 10px 0;
        }

        .lender-profile-text {
            @extend .typography-lender-profile-text;
            margin-top: 60px;
            overflow-y: auto;
            word-break: break-word;
            min-height: 200px;
            max-height: 200px;
        }

        .lender-profile-link {
            @extend .typography-lender-profile-text;
            padding: 0 30px;

            a {
                color: $red;
                text-decoration: none;
            }
        }

        .lender-profile-description {
            padding: 30px 100px 30px 30px;
            white-space: pre-line;
        }

        .lender-profile-button {
            background-color: $red;
            width: 190px;
            position: absolute;
            bottom: 70px;
            left: 60px;
        }

        .favourite {
            position: relative;
            float: left;
            margin-right: 1rem;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .close-button {
            position: fixed;
            top: 15px;
            right: 15px;
            z-index: 999;
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.1);

            .MuiSvgIcon-root {
                font-size: 25px;
                color: $very-dark-grey;
            }
        }
    }

    .tab-title {
        color: $provide-black;
        font-family: $provide_font-family-roboto;
        text-transform: none;
        font-size: 14px;
        font-weight: 500;

        .Mui-selected {
            font-weight: 600 !important;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .lender-profile-modal {
        .MuiPaper-root {
            width: 100%;
        }
    }
}

@media (max-height: 480px) {
    .lender-profile-modal {
        .MuiPaper-root {
            .lender-profile-logo {
                max-height: 60px;
            }

            .lender-profile-text {
                margin-top: 20px;
            }

            .lender-profile-button {
                position: relative;
                margin-top: 15px;
                bottom: auto;
                left: auto;
            }
        }
    }
}
