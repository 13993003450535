@import '../base/variables';

.match-debug {
    .MuiDataGrid-root,
    .MuiDataGrid-root {
        position: relative;
        padding: 0;
        min-width: 800px;
        max-width: 800px;
        min-height: 600px;
        font-size: 1rem;
    }
}

.lender-select {
    .MuiSelect-select.MuiSelect-select {
        padding-right: 24px;
        width: 13rem;
        line-height: 2rem;
    }
}

.modal {
    .MuiPaper-root {
        position: relative;
        padding: 0;
        min-width: 300px;
        max-width: 900px;
    }

    @media screen and (max-width: $breakpoint-sm) {
        .MuiPaper-root {
            position: relative;
            padding: 0;
            min-width: 50px;
            max-width: 900px;
        }
    }

    &-title {
        @extend .typography-card-title;
        color: $provide-teal;
        margin: 0;
        padding: 0;
    }

    &-delete-modal-container {
        max-width: 543px;
    }

    &-delete-title {
        @extend .typography-card-title;
        color: $provide-teal !important;
    }

    &-delete-content {
        @extend .typography-card-data;
        padding: 0 50px 0 50px;
    }

    &-content {
        > .MuiGrid-container {
            padding: 36px;
        }

        &.MuiDialogContent-root {
            padding: 0;

            &:first-child {
                padding: 0;
            }
        }

        form {
            .input-autocomplete,
            .text-field {
                margin-top: 25px;
            }
        }

        .field-row {
            border: none;

            .container {
                padding: 0;
            }

            &:last-of-type {
                .container {
                    padding-bottom: 40px;
                }
            }
        }

        .heading {
            @extend .typography-card-title-brand-blue;
        }
    }

    &-content-one-pane {
        max-width: 650px;

        &.completion-rejected-modal {
            .grey-box {
                display: flex;
                flex-direction: row;
                align-content: flex-start;
                text-align: left;
                align-items: center;

                margin-bottom: 20px;

                p {
                    margin: 0;
                }

                .icon {
                    margin-right: 5px;

                    .MuiSvgIcon-root {
                        height: 20px;
                        width: 20px;
                        align-content: center;
                    }
                }
            }

            .completion-rejected-reason {
                .button-panel {
                    display: flex;
                    flex-direction: column;
                    align-content: flex-end;
                    text-align: left;
                    margin-top: 0;

                    button {
                        &:nth-child(2) {
                            margin: 0 0 0 10px;
                        }
                    }
                }
            }
        }

        .MuiGrid-container {
            display: flex;
            flex-direction: column;
            align-content: center;
            text-align: center;

            .typography-default {
                margin-bottom: 20px;
            }

            .button-panel {
                button {
                    width: 132px;
                }

                .MuiButton-root:nth-child(2) {
                    margin: 10px 0 0 0;
                }
            }
        }
    }

    &-content-two-pane {
        &.MuiGrid-container {
            padding: 0;
        }

        .MuiGrid-container {
            padding: 0;
        }

        &.MuiDialogContent-root {
            padding: 0;
        }

        .modal-pane {
            padding: 56px 36px 36px 36px;
            width: 50%;

            &-info {
                display: flex;
                flex-direction: column;
                align-content: center;
                text-align: center;
                border-right: 1px solid $light-grey;

                .typography-default {
                    margin-bottom: 20px;
                }
            }
        }

        .two-pane-form {
            &__title {
                color: $provide-teal;
            }

            &__form {
                width: 100%;
            }

            .button-panel {
                position: absolute;
                bottom: 36px;
                right: 36px;
                width: inherit;
            }
        }
    }

    .close-button {
        position: absolute;
        top: 35px;
        right: 36px;
        padding: 0;

        .MuiSvgIcon-root {
            font-size: 25px;
            color: $very-dark-grey;
        }
    }

    .MuiDialogTitle-root {
        padding: 36px 36px 0 36px;
    }

    .MuiFormControl-root {
        display: block;
    }

    .applicant-date-of-birth {
        display: inline-flex;
        width: 100%;
    }

    .button-panel {
        margin-top: 40px;
    }

    .logoDeleteModal {
        padding: 20px;

        .logoDeleteText {
            margin-left: 15px;
        }
    }
    .create-reset-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 1rem;
        .secondary-button {
            background-color: $very-dark-blue !important;
            color: white;
        }
        .primary-button {
            margin: 0;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .modal-content-two-pane {
        width: 300px;

        .modal-pane {
            padding: 18px;
            width: 100%;

            &-info {
                border: 0;
            }
        }

        .two-pane-form {
            .button-panel {
                position: static;
            }
        }
    }
}
