@import '../../base/variables';

.lender-terms-matrix-container {
    margin: 40px;
    color: $provide-black;

    button {
        font-family: $provide-font-family-roboto;
        background-color: $provide-teal;

        &:hover {
            background-color: transparentize($provide-grey-5, 0.2);
        }
    }

    .header {
        color: $provide-teal;
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 20px;
        text-align: center;
    }

    .steps {
        font-family: $provide-font-family-degular-display;
        font-weight: 500;
        font-size: 18px;
        color: $provide-teal;
        text-align: center;
        padding-bottom: 20px;
    }

    .sub-header {
        font-family: $provide-font-family-roboto;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        padding-bottom: 30px;
    }

    .actions-container {
        text-align: right;
        margin-top: 20px;

        .btn-next {
        }

        .btn-back {
        }

        .btn-export {
            margin-left: 10px;
        }
    }

    .step-one table {
        width: 100%;

        thead tr th {
            text-align: left;
            width: 33%;
            font-family: $provide-font-family-degular-display;
            font-weight: 500;
            font-size: 16px;

            &.th-include {
                text-align: center;
            }
        }

        tbody tr td {
            font-family: $provide-font-family-roboto;
            font-size: 14px;
            font-weight: 400;
        }

        .lender-name {
            width: 70%;
        }

        .lender-include {
            width: 30%;
            text-align: center;
        }

        .no-terms {
            margin-bottom: 10px;
            display: block;
            font-style: italic;

            span {
                color: $provide-teal;
            }
        }

        .blank-spaces-select {
            text-align: center;
        }
    }

    .step-two {
        .MuiPaper-rounded {
            border-radius: 4px !important;
        }

        .MuiAccordion-rounded {
            border-radius: 0 !important;
        }

        .MuiAccordion-rounded:first-child {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }

        .MuiAccordion-rounded:last-child {
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }

        table {
            width: 100%;

            thead tr th {
                text-align: left;
                width: 33%;
                font-family: $provide-font-family-degular-display;
                font-weight: 500;
                font-size: 16px;

                &.th-include {
                    text-align: center;
                }
            }

            tbody tr td {
                font-family: $provide-font-family-roboto;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .term-include {
            text-align: center;
        }

        .term-type {
            font-family: $provide-font-family-degular-display;
            font-size: 16px;
        }
    }
}

.lender-terms-matrix-confirmation-modal-container {
    padding: 50px;
    text-align: center;
    font-family: $provide-font-family-roboto;

    .icon-container {
        padding: 40px 0;
    }

    .header-container {
        color: $provide-teal;
        font-weight: 500;
        font-size: 18px;
        padding-bottom: 20px;
        line-height: 24px;
        font-family: $provide-font-family-degular-display;
    }

    .text-container {
        color: $provide-black;
        font-size: 14px;
        padding-bottom: 40px;
    }

    .action-container {
        text-align: right;

        .btn-back {
            margin-right: 10px;
        }

        .btn-export {
            .cloud-icon {
                margin-right: 5px;
            }
        }
    }
}
