@import '../../base/variables';

.chat-icon {
    @extend .typography-chat-icon;
    color: $red;
    margin: auto;
    padding-right: 10px;
    justify-content: center;

    > img {
        padding-bottom: 12px;
        width: 30px;
    }

    .forename {
        clear: both;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        text-align: center;
    }

    .is-admin {
        clear: both;
        width: 100%;
        color: $dark-grey;
    }
}
