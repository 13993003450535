@import '../../base/variables';

.contract-download {
    padding: 60px;
    .progress,
    .failed {
        text-align: center;
    }
    .failed {
        margin-top: 20px;
    }
}
