.loan-enquiries-context-menu {
    border: 1px solid $light-grey;
    background: $white;
    border-radius: 2px;
    position: relative;
    height: 44px;
    width: 40px;
    padding-top: 12px;
    padding-left: 10px;
}
.leads-context-menu {
    border: 1px solid $light-grey;
    background: $white;
    border-radius: 2px;
    position: relative;
    height: 44px;
    width: 40px;
    padding-top: 12px;
    padding-left: 10px;
    margin-left: 15px;
}
