@import '../base/variables';

.introducer-borrower-registration,
.borrower-product-registration {
    height: 100vh;
    align-content: flex-start;
    background-color: #ffffff;

    .item-left {
        padding-right: 20px;
    }

    .form-heading {
        color: $provide-black;
        font-size: 30px;
        text-align: center;
        font-family: $provide-font-family-degular-display;
        font-weight: 500;
    }

    .dpl-panel {
        font-size: 14px;
        color: $dark-grey;
        padding-top: 15px;
        border-top: 1px solid #f2f2f2;
    }

    .privacy-panel {
        font-size: 12px;
        color: $dark-grey;
        text-align: center;
        border-top: 1px solid #f2f2f2;
        padding-top: 20px;
    }

    .privacy-panel a {
        color: $provide-teal;
    }

    .strong {
        font-weight: 600;
    }

    .button-submit {
        background-color: $provide-teal !important;

        &.disabled {
            background-color: $dark-grey-faded-20 !important;
        }
    }

    .left-container {
        background-color: #ffffff;
        height: 100%;
        align-content: flex-start;
    }

    .heading {
        font-size: 65px;
        margin-bottom: 0;
        color: $provide-purple;
        text-align: left;
        line-height: 65px;
        font-weight: 400;
        font-family: $provide-font-family-degular-display;
    }

    .giftware-heading {
        font-size: 65px;
        margin-bottom: 0;
        color: #8c6bd0 !important;
        margin-left: 20%;
        text-align: left;
        line-height: 65px;
        font-weight: 400;
        font-family: $provide-font-family-degular-display;
    }

    .right-container {
        align-items: flex-start;
        padding-top: 100px;
        background-image: url('../images/product-registration-bg.png');
        background-size: cover;
        background-position: right;
        background-color: $black;
        border: none;

        &.introducer-giftware {
            background-image: url('../images/introducers/giftware-bg.png');

            .heading-container {
                justify-content: left;

                .heading {
                    color: #8c6bd0 !important;
                    margin-left: 20%;
                }
            }
        }
    }

    .sub-heading {
        font-size: 20px;
        margin-bottom: 0;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
    }

    .logo {
        margin-left: 20px;
        margin-top: 40px;
        margin-bottom: 15px;
        max-width: 150px;
    }

    .page-header {
        border-bottom: 1px solid #c4c3c3;
        background-color: #ffffff;
        height: 100px;

        .giftware-logo {
            background-image: url('../images/introducers/giftware-logo.png');
            background-repeat: no-repeat;
            background-size: contain;
            min-height: 6rem;
            max-width: 16%;
            margin: 1rem auto 0;
            position: relative;
            z-index: 2;
        }
    }

    .footer-container {
        @extend .typography-small;
        color: $dark-grey;
        background-color: #ffffff;
        margin-bottom: 20px;

        & a {
            color: $dark-grey;
            text-decoration: underline;
        }

        .links {
            margin-top: 10px;
            line-height: 18px;

            & a {
                margin: 0 8px;
            }
        }
    }

    .container {
        align-self: center;
        justify-content: center;
        background-color: #ffffff;

        .registration-branding-container {
            margin-bottom: 1.7rem;
        }

        .content-container {
            background-color: white;
            padding: 40px 40px 20px 40px;

            &.borrower-registration-form-item {
                display: flex;
                justify-content: center;
            }

            .borrower-registration-form-container {
                width: 70%;
            }

            .body {
                @extend .typography-authentication-body;
                line-height: 24px;
            }

            .form-container {
                margin-bottom: 30px;
            }

            .form-messages {
                margin-bottom: 30px;
            }

            .heading {
                @extend .typography-card-title-brand-blue;
                margin: 0;
            }

            .button-panel {
                margin-top: 40px;
                margin-bottom: 20px;
            }

            .text-field,
            .input-autocomplete {
                margin-top: 31px;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-xs) {
    .introducer-borrower-registration {
        .container {
            .content-container {
                &.borrower-registration-form-item {
                    padding: 0;
                }
            }
        }

        .page-header {
            border-bottom: 1px solid #c4c3c3;
            background-color: #ffffff;
            height: 100px;

            .giftware-logo {
                background-image: url('../images/introducers/giftware-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                height: 6rem;
                max-width: 138px;
                margin: 1rem auto;
                position: relative;
                z-index: 2;
            }
        }
        .giftware-heading {
            font-size: 65px;
            color: #8c6bd0 !important;
            text-align: left;
            line-height: 65px;
            font-weight: 400;
            font-family: $provide-font-family-degular-display;
            margin: 0;
            padding: 0 3rem 10rem 3rem;
        }
    }
}

@media only screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .introducer-borrower-registration {
        .container {
            .content-container {
                &.borrower-registration-form-item {
                    padding: 5rem 0 0 0;
                }
            }
        }
        .page-header {
            border-bottom: 1px solid #c4c3c3;
            background-color: #ffffff;
            height: 100px;

            .giftware-logo {
                background-image: url('../images/introducers/giftware-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                min-height: 6rem;
                max-width: 138px;
                margin: 1rem auto;
                position: relative;
                z-index: 2;
            }
        }
        .giftware-heading {
            font-size: 65px;
            color: #8c6bd0 !important;
            text-align: left;
            line-height: 65px;
            font-weight: 400;
            font-family: $provide-font-family-degular-display;
            margin: 0;
            padding: 0 3rem 10rem 3rem;
        }
    }
}

@media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .borrower-product-registration {
        .item-left {
            padding-right: 0;
        }

        .container {
            .content-container {
                .borrower-registration-form-container {
                    width: 100%;
                }
            }
        }
    }

    .introducer-borrower-registration {
        .page-header {
            border-bottom: 1px solid #c4c3c3;
            background-color: #ffffff;
            height: 100px;

            .giftware-logo {
                background-image: url('../images/introducers/giftware-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                min-height: 6rem;
                max-width: 138px;
                margin: 1rem auto 0;
                position: relative;
                z-index: 2;
            }
        }
        .giftware-heading {
            font-size: 65px;
            color: #8c6bd0 !important;
            text-align: left;
            line-height: 65px;
            font-weight: 400;
            font-family: $provide-font-family-degular-display;
            margin: 0;
            padding: 0 3rem 10rem 3rem;
        }
    }
}

@media only screen and (min-width: 900px) and (max-width: $breakpoint-md) {
    .introducer-borrower-registration {
        .page-header {
            border-bottom: 1px solid #c4c3c3;
            background-color: #ffffff;
            height: 100px;

            .giftware-logo {
                background-image: url('../images/introducers/giftware-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                min-height: 6rem;
                max-width: 138px;
                margin: 1rem auto 0;
                position: relative;
                z-index: 2;
            }
        }
        .giftware-heading {
            font-size: 65px;
            margin-bottom: 0;
            color: #8c6bd0 !important;
            text-align: left;
            line-height: 65px;
            font-weight: 400;
            font-family: $provide-font-family-degular-display;
        }
    }

    .borrower-product-registration {
        &.form-heading-container {
            padding-top: 0;
        }

        .item-left {
            padding-right: 0;
        }

        .left-container {
            align-content: flex-start;
        }

        .right-container {
            display: none;
        }

        .container {
            align-self: auto;

            .branding-container {
                margin-bottom: 30px;
            }

            .content-container {
                .borrower-registration-form-container {
                    width: 100%;
                }

                .form-container {
                    margin-bottom: 20px;
                }

                .form-messages {
                    margin-bottom: 20px;
                }

                .text-field,
                .input-autocomplete {
                    margin-top: 20px;
                }
            }

            .footer-container {
                margin-top: 15px;
            }
        }
    }
}
