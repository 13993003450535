@import '../../base/variables';

.contract-progress-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        margin: 20px 0;
        max-width: 130px;
        text-align: center;
    }
    .text {
        max-width: 190px;
        font-size: 1rem;
        line-height: unset;
        text-align: center;
    }
}
