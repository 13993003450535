@import '../base/variables';

.main {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 20px;
    @media (max-width: $breakpoint-md) {
        margin: 20px 1rem;
    }
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.header-with-count {
    .count {
        color: $provide-teal;
        font-weight: 700;
    }

    .text {
        color: $provide-black;
        font-weight: 700;
    }
}
