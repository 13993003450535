.make-loan-enquiry {
    .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
    }

    .MuiTypography-body2 {
        font-size: 1.1em !important;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
        width: 24px;
        height: 24px;
        color: $provide-teal;
    }

    .MuiTypography-body1 {
        font-size: 1rem;
        font-family: degular-display;
        font-weight: 400;
        line-height: 1.5;
    }

    .MuiTypography-h3 {
        color: $provide-teal;
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color: $provide-black;
    }
    .buttons-delete-draft {
        margin: 0 0 0 0;
        padding: 0 3.5rem 0 0;
        display: inline-flex;
        justify-content: flex-end;
        flex-flow: row wrap;
        grid-gap: 2rem 2rem;
        gap: 2rem 2rem;
        width: 100%;

        .draft-save-button {
            color: $provide-black;
        }

        .draft-delete-button {
            color: $form-error;
        }

        .draft-save-button,
        .draft-delete-button {
            padding: 0;
            margin: 0 2rem 0 0;
            line-height: 3.2rem;
        }

        .draft-save-button:hover,
        .draft-delete-button:hover {
            padding: 0;
            margin: 0 2rem 0 0;
            line-height: 3.2rem;
            background-color: unset;
            color: $provide-teal;
        }
    }

    .make-loan-enquiry-buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        gap: 2rem 2rem;
    }

    .MuiCardContent-root:last-child {
        padding: 35px;
    }

    .MuiSelect-select.MuiSelect-select {
        width: 22.2rem;
        height: 2rem;
        line-height: 2rem;
    }

    .MuiOutlinedInput-input {
        width: 23rem;
    }

    .bold {
        font-weight: 600;
    }
    @media screen and (max-width: $breakpoint-sm) {
        @at-root .main {
            .MuiGrid-root {
                padding: 0;
            }
            padding: 0;
        }

        .spacer {
            display: none;
        }
        .business-stream-telephone-box-wrapper {
            display: flex;
            flex-direction: row-reverse;
            width: unset;
            padding-right: 0;
            padding-left: 0;
        }
        .notice .notice-default {
            border-radius: 1rem;
        }

        p {
            padding: 0;
            margin: 0;
        }

        .notice-message {
            padding: 6px !important;
            margin: 0;
        }
        .applicantButtons {
            .MuiOutlinedInput-input {
                min-width: 300px !important;
            }
            button {
                min-width: 8rem;
                padding: 1.585rem 0.647rem;
                margin: 0 0 0 0;
                height: 3rem;
            }
        }
        .MuiInputBase-root,
        .MuiFormControl-root {
            max-width: 100%;
        }

        .MuiStepper-root {
            padding: 3rem 0 0 0;
        }

        .MuiStepContent-root {
            padding-right: 0;
            border-left: none;
            margin-left: 0;
            padding-left: 0;
        }

        .add-button,
        .next-button,
        .back-button,
        .save-draft-button {
            height: 3rem;
            min-width: 8rem;
            padding: 1.585rem 0.647rem;
            margin: 0 2rem 0 0;
            color: $white;
            background-color: $provide-black;
        }
        .buttons-delete-draft {
            margin: 0 0 0 0;
            padding: 0 3.5rem 0 0;
            display: inline-flex;
            justify-content: flex-end;
            flex-flow: row wrap;
            grid-gap: 2rem 2rem;
            gap: 2rem 2rem;
            width: 100%;
        }
    }

    @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        .applicantButtons {
            .MuiOutlinedInput-input {
                min-width: 300px !important;
            }
            button {
                padding: 1.585rem 0.647rem;
                margin: 0 0 0 0;
                height: 40px;
            }
        }

        .spacer {
            display: none;
        }
        .business-stream-telephone-box-wrapper {
            display: flex;
            flex-direction: row-reverse;
            width: unset;
            padding-right: 0;
            padding-left: 0;
            .notice-header {
                margin-left: 3.6rem;
            }
        }
        .notice .notice-default {
            border-radius: 1rem;
        }
        .MuiInputBase-root,
        .MuiFormControl-root {
            max-width: 100%;
        }

        .MuiStepper-root {
            padding: 3rem 0 0 0;
        }

        .MuiStepContent-root {
            padding-right: 0;
            border-left: none;
            margin-left: 0;
            padding-left: 0;
        }

        .add-button,
        .next-button,
        .back-button,
        .save-draft-button {
            height: 3rem;
            min-width: 8rem;
            padding: 1.585rem 0.647rem;
            margin: 0 2rem 0 0;
            color: $white;
            background-color: $provide-black;
        }
        .buttons-delete-draft {
            margin: 0 0 0 0;
            padding: 0 3.5rem 0 0;
            display: inline-flex;
            justify-content: flex-end;
            flex-flow: row wrap;
            grid-gap: 2rem 2rem;
            gap: 2rem 2rem;
            width: 100%;
        }
    }

    @media screen and (min-width: $breakpoint-md) {
        .applicantButtons {
            .MuiOutlinedInput-input {
                min-width: 300px !important;
            }
            button {
                padding: 1.585rem 0.647rem;
                margin-left: 10px;
                height: 40px;
            }
        }

        .add-button,
        .next-button,
        .back-button,
        .save-draft-button {
            height: 3rem;
            min-width: 8rem;
            padding: 1.585rem 0.647rem;
            margin: 0 2rem 0 0;
            color: $white;
            background-color: $provide-black;
        }
        .buttons-delete-draft {
            margin: 0 0 0 0;
            padding: 0 3.5rem 0 0;
            display: inline-flex;
            justify-content: flex-end;
            flex-flow: row wrap;
            grid-gap: 2rem 2rem;
            gap: 2rem 2rem;
            width: 100%;
        }
    }
}
