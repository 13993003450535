@import '../base/variables';

.dashboard-borrower,
.dashboard-slam,
.dashboard-admin,
.dashboard-intermediary {
    .header-container {
        display: flex;
        justify-content: space-between;
        color: $provide-grey-5;

        .welcome {
            font-size: 30px;
            font-family: $provide-font-family-degular-display;
            font-weight: 500;
            color: $provide-black;
        }

        .logged-in-as {
            font-size: 1rem;
            color: $dark-grey;

            .user-type {
                font-weight: 700;
            }
        }

        .help {
            text-align: right;
            flex-direction: column;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-family: $provide-font-family-degular-display;
            font-weight: 500;
            font-size: 18px;
            color: $dark-grey;

            .icon {
                margin-right: 10px;
            }

            .text {
                font-family: $provide-font-family-roboto;
                font-size: 14px;
                font-weight: 700;
                color: $dark-grey;
            }
        }
    }
}

@media only screen and (max-width: 735px) {
    .dashboard-intermediary {
        .header-container {
            display: block;

            .help {
                margin-top: 20px;

                .text {
                }
            }
        }
    }
}
