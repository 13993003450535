@import '../base/variables';

.admin-case-management-icon-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .bubble {
        width: 80%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background: rgb(2, 0, 36);
        background: linear-gradient(170deg, rgba(2, 0, 36, 1) 0%, rgba(11, 151, 179, 1) 86%, rgba(0, 212, 255, 1) 100%);
        border-radius: 50%;
        -webkit-box-shadow: 0 5px 16px -6px $black;
        box-shadow: 0 5px 16px -6px $black;
        cursor: pointer;

        .icon {
            font-size: 40px;
            color: $white;
        }

        .alert-active {
            background-color: $supporting-cyan;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            left: 10px;
            border-radius: 10px;
            border: 3px solid white;
        }
    }
}
