@import '../../../base/variables';

.admin-note-container {
    .admin-notes {
        .p4style-component-container {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            padding-top: 0;
        }

        .top-bar {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
        }

        .note-actions {
            justify-content: space-between;

            .btn-delete {
                color: $provide-teal;

                &.active {
                    background-color: $medium-grey;
                    color: $white;
                }
            }

            .btn-edit {
                color: $dark_grey;
            }
        }

        .notes {
            width: 100%;

            .MuiPaper-rounded {
                border-radius: unset !important;
            }

            .summary {
                display: flex;
                align-items: center;
                width: 100%;
                font-family: Roboto, Helvetica, Arial, sans-serif, serif !important;
            }

            .icon {
                color: $light-grey;
                margin-right: 20px;
            }

            .title {
                color: $black;
                font-size: 14px;
                padding: 0;
                text-align: left;
                font-family: Roboto, Helvetica, Arial, sans-serif, serif !important;
                word-break: break-all;
                width: 30%;
            }

            .created-at {
                color: $dark-grey;
                font-size: 14px;
                min-width: 150px;
                font-family: Roboto, Helvetica, Arial, sans-serif, serif !important;
            }

            .submitted-by {
                padding: 0 50px;

                span {
                    background-color: $dark-grey;
                    color: $white;
                    font-family: Roboto, Helvetica, Arial, sans-serif, serif !important;
                }
            }

            .note {
                white-space: pre-line;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .admin-note-container {
        .admin-notes {
            .notes {
                .summary {
                    display: block;
                }

                .submitted-by {
                    padding: 5px 0;
                }
            }
        }
    }
}
