@import '../../base/variables';

.business-stream-summary-table,
.mle-summary-table {
    .summary-table-section {
        padding: 40px 0;
        border-bottom: 1px solid $light-grey;

        .color-red {
            color: $provide-grey-5;
        }

        .summary-table-section-heading {
            @extend .typography-card-title;
            font-weight: 700 !important;

            &.color-red {
                color: $provide-black;
            }
        }

        .summary-table-section-heading-small {
            @extend .typography-card-data;
            width: 100%;
        }

        .terms-checkbox {
            p {
                @extend .typography-small;
                margin: 15px 0 0;
                width: 100%;
            }

            .checkbox {
                margin-top: 30px;
                width: 100%;

                .checkbox-label-small {
                    @extend .typography-small;
                }

                & {
                    width: 100%;
                }
            }
        }

        .field-row {
            border-bottom: none;

            .container {
                padding: 36px 0 0;
            }
        }
    }

    .summary-table-section:first-child {
        padding-top: 0;
    }

    .summary-table-section:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
}

.mle-summary-table {
    .summary-table-section:first-child {
        padding-top: 40px;
    }
}
