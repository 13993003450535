@import '../../base/variables';

.contract-confirmation-container {
    padding: 40px;

    .header {
        color: $provide-black;
        font-family: $provide-font-family-degular-display;
        margin-bottom: 30px;
        font-size: 30px;
        text-align: center;
        font-weight: 500;
    }

    .checkbox {
        padding-top: 0;
        padding-right: 20px;

        &.Mui-checked {
            color: $provide-teal !important;
        }
    }

    .section {
        padding: 30px 30px 30px 15px;
        display: flex;

        .text-content {
            cursor: pointer;
            color: $dark-grey;
        }

        .t-and-c-link {
            color: $provide-teal;
            text-decoration: underline;
        }
    }

    .lenders-input {
        .MuiInputBase-root {
            width: 100%;
            background-color: $very-light-grey;
            font-family: $provide-font-family-roboto;
            font-size: 14px;
            border-radius: 4px !important;
        }
    }

    .actions {
        margin-top: 20px;
        text-align: right;

        button {
            font-family: $provide-font-family-roboto;
            font-size: 14px;
            font-weight: 700;
        }
    }
}
