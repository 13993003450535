@import '../../base/variables';

.hyperlink {
    text-decoration: none;
    color: $very-dark-blue;
    border: none;
    cursor: pointer;
    background: none;
    font-family: inherit;
}

span.lender-profile-enabled:hover {
    text-decoration: underline;
}
