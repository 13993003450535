@import '../../base/variables';

.data-grid-table-status {
    display: block;
    height: 10px;
    border-radius: 5px;

    &.matched {
        background-color: $status-matched-color;
        width: percentage(1/6);
    }

    &.in_progress {
        background-color: $status-in-progress-color;
        width: percentage(2/6);
    }

    &.application {
        background-color: $status-application-color;
        width: percentage(3/6);
    }

    &.valuation {
        background-color: $status-valuation-color;
        width: percentage(4/6);
    }

    &.legals {
        background-color: $status-legals-color;
        width: percentage(5/6);
    }

    &.completed {
        background-color: $status-completed-color;
        width: percentage(6/6);
    }

    &.completion_requested {
        background-color: $status-completed-color;
        width: percentage(6/6);
    }

    &.completion_rejected {
        background-color: $status-completed-color;
        width: percentage(6/6);
    }
}
