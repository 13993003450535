@import '../base/typography';
@import '../base/variables';

.joyride-tooltip-body {
    background-color: $header-bg;
    min-width: 290px;
    max-width: 420px;
    position: relative;
    padding: 40px;

    .joyride-tooltip-content {
        @extend .typography-card-subtitle;
        color: $dark-grey;
        line-height: 21px;
    }

    .joyride-tooltip-title {
        @extend .typography-card-title-chunky;
        margin: 0 0 25px 0;
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;

        .joyride-tooltip-img {
            width: 30px;
            height: 30px;
            margin-right: 20px;
        }

        .joyride-tooltip-title-text {
            background: linear-gradient(to right, #bbbae8, #2ec4b6) repeat;
            background-size: 100%;
            color: $dark-grey;
            text-align: left;
            justify-content: left;
            align-items: center;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .joyride-tooltip-footer {
        margin-top: 30px;
        justify-content: space-between;

        .button {
            @extend .typography-button;

            text-transform: uppercase;
            color: $provide-teal;
            cursor: pointer;
            justify-content: flex-end;
            margin-left: auto;
        }

        .joyride-row {
            display: flex;
            justify-content: space-between;
        }

        .joyride-status-widget-count {
            margin-bottom: 10px;
        }
    }
}
