@import '../../base/variables';

.admin-leads-container-main {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    min-height: 560px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .MuiDataGrid-main {
        min-height: 400px;
    }
}

.admin-leads-container-main,
.leads-container-main {
    .lenders-badge {
        @extend .typography-badge;
        width: 87px;
        height: 87px;
        color: white;
        background-color: $provide-purple;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        right: 50px;
        margin-top: 20px;
        border: 4px solid $provide-white-faded-70;

        .count {
            font-size: 20px;
            margin-top: 25px;
        }
    }
}

.admin-leads-container-header {
    margin-bottom: 20px;
}

.leads-container-header {
    .label {
        display: flex;
        align-items: center;
        font-weight: normal;
        color: $provide-grey-6;

        .icon {
            margin-right: 15px;
        }

        .count {
            color: $provide-teal;
            margin-left: 3px;
        }
    }
}

.leads-container-header,
.admin-leads-container-header {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 130px 30px 30px;

    .divider {
        border-right: 1px solid $medium-grey;
        height: 20px;
        margin: 0 10px 0 10px;
    }

    .label {
    }

    .actions {
        display: flex;
        align-items: center;
    }

    .select-all,
    .select-fav {
        display: flex;
        align-items: center;

        .label {
            padding-top: 1px;
            color: $medium-grey;
        }

        .icon {
            margin: 0 0 0 5px;
            font-size: 24px;
            cursor: pointer;
            color: $provide-grey-5;
        }
    }

    .sort-by {
        font-family: 'Roboto', serif;
        font-weight: 700;
        color: $medium-grey;

        display: flex;
        align-items: center;

        .label {
            padding-top: 1px;
        }

        .list {
            .MuiSelect-root {
                padding: 0 10px;
                font-size: 15px;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }

    .view-list {
        display: flex;
        align-items: center;

        .icon {
            font-size: 30px;
            color: $light-grey-1;
            cursor: pointer;

            &.active {
                color: $dark-grey;
            }
        }
    }

    .view-cards {
        display: flex;
        align-items: center;

        .icon {
            font-size: 33px;
            margin-top: 2px;
            color: $light-grey-1;
            cursor: pointer;

            &.active {
                color: $dark-grey;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .leads-container-header,
    .admin-leads-container-header {
        display: block;
        padding-bottom: 10px;

        .divider {
            display: none;
        }

        .actions {
            display: block;
        }

        .select-all,
        .select-fav {
            margin-top: 10px;
        }

        .sort-by {
            margin-top: 10px;

            .list {
                margin-left: 10px;

                .MuiSelect-root {
                    padding: 10px;
                    font-size: 15px;
                }
            }
        }

        .view-list {
            .icon {
                font-size: 33px;
                margin: 0;
            }
        }

        .view-cards {
            .icon {
                font-size: 35px;
                margin-top: 1px;
            }
        }
    }
    .admin-leads-container-header {
        display: flex;
    }
}
