@import '../../base/variables';

.legal-contract {
    display: flex;
    align-items: center;

    .icon {
        color: $snackbar-green;
        margin-right: 5px;
        font-size: 25px;
    }

    .title {
        color: $dark-grey;
        font-size: 1rem;
        text-align: left;
        margin: 0;
        padding: 0;
    }

    .link {
        color: $provide-teal;
        font-size: 0.8rem;
        cursor: pointer;
    }

    .unsigned {
        color: $provide-teal;
        font-weight: 600;
    }
}
