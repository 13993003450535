@import '../../base/variables';

.message-list {
    .messages {
        min-height: 300px;
        //max-height: 540px;
        overflow: auto;
        width: 100%;
        margin-bottom: 120px;
        padding-top: 20px;

        .message-row {
            white-space: pre-line;
            padding: 0 $p4style-container-padding-horiz 0 $p4style-container-padding-horiz;
            margin-bottom: 30px;
            clear: both;
            font-weight: 400;

            &.start-chat {
                text-align: left;
                color: $light-grey;
                height: 50px;
                font-size: 14px;
                line-height: 50px;
            }

            &:first-of-type {
                padding-top: 0;
            }

            &.is-sender {
                float: right;

                .bubble-text.top-right.outgoing {
                    margin-right: 0;
                }

                .bubble-text.top-right.outgoing::after {
                    border: none;
                    right: 0;
                }
            }

            &.role-borrower {
                .bubble-text.incoming {
                    background-color: $provide-borrower-color;
                }

                .bubble-text.incoming:after {
                    border-right-color: $provide-borrower-color;
                }
            }

            &.role-intermediary {
                .bubble-text.incoming {
                    background-color: $provide-intermediary-color;
                }

                .bubble-text.incoming:after {
                    border-right-color: $provide-intermediary-color;
                }
            }

            &.role-slam {
                .bubble-text.incoming {
                    background-color: $provide-lender-color;
                }

                .bubble-text.incoming:after {
                    border-right-color: $provide-lender-color;
                }
            }

            &.role-admin {
                color: $dark-grey;
                background-color: $white;

                .bubble-text {
                    border: solid 1px $light-grey;
                    background-color: $white;
                    color: $dark-grey;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 10px;
                        width: 2px;
                        height: 0;
                        border: 13px solid transparent;
                        border-left: 0;
                        margin-top: -10px;
                        margin-left: -15px;
                        z-index: 10000;
                    }

                    &.top-left {
                        border-top-left-radius: 0;

                        &.incoming:after {
                            border-top: 0;
                            left: -5px;
                            top: 9px;
                            border-right-color: $light-grey;
                        }

                        &.incoming:before {
                            border-top: 0;
                            left: 0;
                            border-right-color: $white;
                        }
                    }
                }
            }

            .message-body,
            .message-timestamp {
                font-weight: 400;
            }

            .message-body {
                line-height: 24px;
            }

            .bubble-text {
                margin-top: 12px;
                padding: 15px 9px 5px 15px;
                min-width: 250px;
            }
        }
    }

    .form {
        position: fixed;
        bottom: 0;
        width: 680px;
        z-index: 10000;

        form {
            background-color: $info-box-bg;

            .chat-form {
                padding: $p4style-container-padding-vert $p4style-container-padding-horiz;

                textarea {
                    resize: none;
                    width: 100%;
                    background-color: white;
                    border-radius: 3px;
                    border: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .message-list {
        .form {
            width: 100%;
        }
    }
}
