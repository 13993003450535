@import '../../../base/variables';

.chip {
    .MuiChip-root {
        @extend .typography-card-data;
        line-height: 1.1;
    }

    .MuiChip-colorPrimary {
        color: $light-font;
        background-color: $very-dark-blue;
    }

    .MuiChip-colorSecondary {
        color: $light-font;
        background-color: $red;
    }

    .MuiChip-status-matched {
        background-color: $status-matched-color;
        color: $light-font !important;
    }

    .MuiChip-status-in-progress {
        background-color: $status-in-progress-color;
        color: $light-font !important;
    }

    .MuiChip-status-application {
        background-color: $status-application-color;
        color: $light-font !important;
    }

    .MuiChip-status-valuation {
        background-color: $status-valuation-color;
        color: $light-font !important;
    }

    .MuiChip-status-legals {
        background-color: $status-legals-color;
        color: $light-font !important;
    }

    .MuiChip-status-completed {
        background-color: $status-completed-color;
        color: $light-font !important;
    }

    .MuiChip-status-withdrawn {
        background-color: $status-lender-declined-color;
        color: $light-font !important;
    }

    .MuiChip-status-completion-requested {
        background-color: $status-completion-requested-color;
        color: $light-font !important;
    }

    .MuiChip-status-completion-rejected {
        background-color: $status-completion-rejected-color;
        color: $light-font !important;
    }

    .MuiChip-status-lender-declined {
        background-color: $status-lender-declined-color;
        color: $light-font !important;
    }
}
