@import '../../base/variables';

.application-view-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 30px;
    padding-bottom: 20px;

    .placeholder-card,
    .lender-card {
        height: 135px;
        width: 222px;
        border: 1px solid $light-grey;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        border-radius: 20px;

        .icon {
            max-width: 72px;
        }
    }

    .placeholder-card {
        justify-content: center;
        cursor: unset;

        &.interactive {
            cursor: pointer;

            &:hover {
                box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                background-color: $main-bg;
            }
        }
    }

    .lender-card {
        flex-direction: column;

        &:hover {
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            background-color: $main-bg;
        }

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            cursor: pointer;
        }

        .interest-rate-container {
            margin-top: 15px;

            .rate {
                color: $provide-teal;
                font-weight: 600;
            }
        }

        .footer {
            .select {
                display: flex;
                align-items: center;
            }

            .status {
                .status-chip {
                    height: 6px;
                    width: 20px;
                    margin-right: 5px;
                }

                .select {
                    cursor: pointer;
                    font-size: 25px;
                    margin-bottom: -3px;
                }

                .status-matched {
                    color: $status-matched-color;
                }

                .status-in_progress {
                    color: $status-in-progress-color;
                    font-weight: 600;
                }

                .status-application {
                    color: $status-application-color;
                    font-weight: 600;
                }

                .status-valuation {
                    color: $status-valuation-color;
                    font-weight: 600;
                }

                .status-legals {
                    color: $status-legals-color;
                    font-weight: 600;
                }

                .status-completed {
                    color: $status-completed-color;
                    font-weight: 600;
                }

                .status-ended {
                    color: $dark-grey;
                    font-weight: 600;
                }

                .step-1 {
                    background-color: $status-matched-color;
                }

                .step-2 {
                    background-color: $status-in-progress-color;
                }

                .step-3 {
                    background-color: $status-application-color;
                }

                .step-4 {
                    background-color: $status-valuation-color;
                }

                .step-5 {
                    background-color: $status-legals-color;
                }

                .step-6 {
                    background-color: $status-completed-color;
                }
            }
        }

        .favourite-icon {
            cursor: pointer;
        }

        &.favourite {
            background-color: $main-bg;
            border: 1px solid $light-grey;
        }
    }
}
