@import '../../../base/variables';

.field-row {
    padding: 0;
    border-bottom: 1px solid $light-grey;

    .container {
        padding: 36px 0;
    }

    .label-container {
        min-height: 47px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .token-highlight {
            color: $provide-teal;
            font-weight: 600;
        }
    }

    .MuiFormHelperText-root {
        position: absolute;
        margin-left: 0;
    }

    .MuiFormHelperText-contained {
        top: 44px;
    }

    #field-beacon {
        @extend .typography-chat-icon;
        background: $supporting-pink;
        position: relative;
        left: 50px;
        top: -19px;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        text-align: center;
        z-index: 999;
        font-size: 12px;
        line-height: 18px;
        color: $white;
    }
}
