@import '../base/variables';

.header {
    $navigation-break: 990px;

    .app-bar {
        background-color: $header-bg !important;
        box-shadow: none;
        border-bottom: 1px solid $header-border-color;
        height: 78px;

        .toolbar {
            height: 100%;
            padding-left: 10px;

            .logo {
                position: relative;
                height: 100%;
                padding: 22px 0 0 19px;

                span {
                    display: inline-block;
                    height: 35px;
                    width: 150px;
                    background: url('../images/logo.svg') no-repeat transparent;
                    background-size: cover;
                }

                //Network logos
                &.network-connect, // login screen logo
        &.top-nav-network-connect {
                    // top navigation bar logo
                    padding: 15px 0 0 15px;

                    span {
                        height: 49px;
                        width: 211px;
                        background: url('../images/networks/connect.png') no-repeat transparent;
                        background-size: cover;
                    }
                }

                &.network-warren-finance-ltd, // login screen logo
        &.top-nav-network-warren-finance-ltd {
                    // top navigation bar logo
                    padding: 15px 0 0 15px;

                    span {
                        height: 49px;
                        width: 211px;
                        background: url('../images/networks/warren-finance-ltd.png') no-repeat transparent;
                        background-size: cover;
                    }
                }

                &.network-wealthmax, // login screen logo
        &.top-nav-network-wealthmax {
                    // top navigation bar logo
                    padding: 10px 0 0 10px;

                    span {
                        height: 55px;
                        width: 211px;
                        background: url('../images/networks/wealthmax.png') no-repeat transparent;
                        background-size: cover;
                    }
                }

                &.network-access-financial-services, // login screen logo
        &.top-nav-network-access-financial-services {
                    // top navigation bar logo
                    padding: 5px 0 0 10px;

                    span {
                        height: 60px;
                        width: 211px;
                        background: url('../images/networks/access-financial-services.png') no-repeat transparent;
                        background-size: cover;
                        background-position-x: center;
                    }
                }

                &.network-giftware {
                    // login screen logo
                    padding: 10px 0 0 10px;

                    span {
                        height: 49px;
                        width: 211px;
                        background: url('../images/networks/giftware.png') no-repeat transparent;
                        background-size: cover;
                    }
                }

                &.top-nav-network-giftware {
                    // top navigation bar logo
                    padding: 10px 0 0 10px;

                    span {
                        height: 60px;
                        width: 100px;
                        background: url('../images/networks/giftware.png') no-repeat transparent;
                        background-size: cover;
                    }
                }
            }

            .horizontal-menu {
                .menu-item {
                    text-align: center;
                    text-decoration: none;
                    height: 100%;

                    .menu-dot-indicator {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        bottom: -21px;
                        color: transparent;

                        &.active {
                            color: $provide-black;
                        }
                    }

                    button {
                        font-size: 14px;
                        display: block;
                        background: none;
                        border: none;
                        border-bottom: 3px solid transparent;
                        color: $dark-grey;
                        text-decoration: none;
                        padding: 30px 20px 28px;
                        height: 100%;
                        font-family: $provide-font-family-roboto !important;

                        &:hover {
                            cursor: pointer;

                            .menu-dot-indicator {
                                color: $provide-black;
                            }
                        }
                    }

                    &.active-menu-item {
                        button {
                            color: $provide-grey-5;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                @media (max-width: $navigation-break) {
                    display: none;
                }
            }

            .toolbar-actions {
                height: 100%;

                .toolbar-action-account {
                    display: flex;
                    margin-left: 10px;

                    .MuiAvatar-colorDefault {
                        background: none;
                        color: $dark-grey;
                    }

                    button {
                        padding: 0;
                    }

                    svg {
                        width: 26px;
                        height: 26px;
                    }

                    .account-menu {
                        position: relative;

                        #field-beacon {
                            background: $supporting-pink;
                            position: absolute;
                            left: 22px;
                            width: 18px;
                            height: 18px;
                            border-radius: 9px;
                            text-align: center;
                            z-index: 999;

                            @extend .typography-button;
                            font-size: 12px;
                            line-height: 18px;
                        }

                        .icon-assumed {
                            color: $red;
                        }
                    }
                }

                .toolbar-action-side-menu {
                    margin: auto 0;
                    display: none;

                    .MuiAvatar-colorDefault {
                        background: none;
                        color: $dark-grey;
                    }

                    button {
                        padding: 0;
                    }

                    svg {
                        width: 26px;
                        height: 26px;
                    }

                    @media (max-width: $navigation-break) {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

// The account menu dropdown is a Popper element which sits at the root level of the <body> tag
#account-menu-popper {
    @include BoxShadow(4);

    z-index: 1100; // Must be at least equal to the z-index of the AppBar
    min-width: 220px;
    max-width: 270px;
    background-color: $header-bg;
    border-radius: 20px;
    transition: opacity 0.5s ease;

    .account-menu-links {
        .MuiTypography-root {
            @extend .typography-default;
            color: $dark-grey;
        }

        .account-type {
            .MuiTypography-root {
                @extend .typography-card-data;
                color: $provide-borrower-color;
            }
        }
    }

    .account-details {
        padding: $menu-gutter;
        background: $provide-borrower-color;
        color: $light-font;
        border-radius: 20px 18px 0 0;

        .account-user {
            &:only-child {
                margin: 10px 0;
            }
        }

        .account-company {
            @extend .typography-small;
            margin-top: 8px;
        }
    }

    .MuiListItem-root {
        padding-left: $menu-gutter;
        padding-right: $menu-gutter;
    }

    .MuiListItemIcon-root {
        min-width: ($menu-gutter + 5);
    }

    .arrow {
        position: absolute;
        width: 15px;
        height: 15px;
        margin-top: -5px;
        right: 12px;
        background: $very-dark-blue;
        transform: rotate(45deg);
    }

    &.intermediary-account-menu {
        .account-details,
        .arrow {
            background: $provide-intermediary-color;
        }

        .account-menu-links .account-type .MuiTypography-root {
            color: $provide-intermediary-color;
        }
    }

    &.lender-account-menu {
        .account-details,
        .arrow {
            background: $provide-lender-color;
        }

        .account-menu-links .account-type .MuiTypography-root {
            color: $provide-lender-color;
        }
    }

    &.borrower-account-menu {
        .account-details,
        .arrow {
            background: $provide-borrower-color;
        }

        .account-menu-links .account-type .MuiTypography-root {
            color: $provide-borrower-color;
        }
    }

    &.admin-account-menu {
        .account-details,
        .arrow {
            background: $provide-admin-color;
        }

        .account-menu-links .account-type .MuiTypography-root {
            color: $provide-admin-color;
        }
    }
}
