@import '../base/variables';

.borrower-default-registration,
.borrower-product-registration {
    .item-left {
        padding-right: 20px;
    }

    .sms-verification-container {
        margin-top: 20px;

        .PrivateSwitchBase-root-5 {
            padding-top: 0;
        }

        .header {
            text-align: left;
            font-weight: 600;
            margin-bottom: 10px;
            color: $dark-grey;
        }
    }
}

.introducer-registration-form {
    .text-field {
        margin-top: 0;
    }

    &.giftware {
        .form-heading-container {
            justify-content: left;
        }
    }

    .form-heading {
        &.giftware {
            text-align: unset;
            color: #343535;
            font-family: Inter, sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
        }
    }

    .form-sub-heading {
        &.giftware {
            color: #fa9f3e;
            font-family: Inter, sans-serif;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

.ppc-registration-form {
    .sms-verification-container {
        margin-top: 20px;

        .PrivateSwitchBase-root-5 {
            padding-top: 0;
        }

        .header {
            text-align: left;
            font-weight: 600;
            margin-bottom: 10px;
            color: $dark-grey;
        }
    }
}

@media only screen and (max-width: 960px) {
    .borrower-default-registration {
        .item-left {
            padding-right: 0;
        }
    }
}

@media only screen and (max-width: 900px) {
    .borrower-default-registration {
        .item-left {
            padding-right: 0;
        }
    }
}
