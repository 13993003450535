@import '../../base/variables';

.completion-details {
    margin-right: 20px;
    display: flex;
    align-items: center;
    border: 1px solid $light-grey;
    padding: 0 9px;
    min-width: 327px;

    .header {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $provide-teal;
        margin-right: 30px;
    }

    .value {
        color: $dark-grey;
    }
}
