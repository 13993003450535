@import '../../../base/variables';

.admin-completed-bar {
    margin-bottom: 20px;
    padding: 15px 20px;
    background-color: $main-bg;
    border: 1px solid $light-grey;
    border-radius: 4px;
    color: $dark-grey;
    align-items: center;

    .label {
        color: $very-dark-blue;
    }

    .value {
        color: $dark-grey;
    }

    .completion-details-link,
    .completion-details-header {
        font-weight: 700;
    }

    .completion-details-link {
        color: $provide-teal;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
    }
}
