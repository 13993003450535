@import '../../../base/variables';

.MuiPickersModal-dialogRoot {
    .MuiTypography-body1,
    .MuiTypography-body2,
    .MuiPickersCalendarHeader-dayLabel,
    .MuiButton-text {
        font-size: 14px;
    }

    .MuiPickersToolbar-toolbar {
        background-color: $provide-grey-5;
    }

    .MuiPickersDay-day {
        &.MuiPickersDay-daySelected {
            background-color: $provide-grey-5;
        }

        &.MuiPickersDay-current {
            color: $provide-teal;

            &.MuiPickersDay-daySelected {
                color: white;
            }
        }
    }

    .MuiButton-text {
        color: $provide-grey-5;
    }
}
