@import '../base/variables';

.banner-notice-system-container {
    margin-left: 30px;
    margin-right: 20px;
    margin-bottom: -20px; // Hack because the of .breadcrumbs (fix and remove).
}

.banner-notice {
    @extend .typography-default;
    padding: 15px;
    margin: 15px 0 20px 0;
    text-align: center;
    border-radius: 0;
    color: $dark-grey;
    width: 100%;
    height: 100%;
    background-color: $info-box-bg;
    line-height: 160%;
    position: relative;

    svg {
        margin-right: 20px;
        vertical-align: middle;
        width: 25px;
        height: 25px;
    }

    a {
        text-decoration: underline;
    }

    &-error {
        color: white;

        a {
            color: white;
        }

        background-color: $provide-teal;

        .primary-button {
            margin-left: 40px;
        }
    }

    &-info {
        color: white;

        a {
            color: white;
        }

        background-color: $provide-admin-color;

        .primary-button {
            margin-left: 40px;
        }
    }

    &.admin-assist {
        margin-top: 40px;
        background-color: $red;
        color: $white;
        padding: 15px 0;
    }
}

@media (max-width: $breakpoint-md) {
    .banner-notice {
        .primary-button.MuiButtonBase-root.MuiButton-containedPrimary {
            margin: 10px auto 0 auto;
        }
        .primary-button {
            display: block;
            margin: 10px auto 0 auto;
        }
    }
}
