@import '../../../base/variables';

.MuiRadio-colorSecondary.Mui-checked:hover,
.MuiRadio-root:hover,
.MuiIconButton-colorSecondary:hover,
.MuiIconButton-colorSecondary:hover {
    background-color: $provide-teal-faded-20 !important;
}

.checkbox,
.radio {
    .MuiFormLabel-root.Mui-focused {
        color: inherit;
    }

    .radio-group-label {
        @extend .typography-checkbox-label;
        margin-bottom: 36px;
    }

    .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
    }

    .MuiFormControlLabel-root {
        .MuiIconButton-root {
            margin-left: 4px;
        }

        .Mui-checked {
            color: $provide-black;

            &:hover {
                background-color: transparentize($provide-grey-5, 0.9);
            }
        }

        .MuiIconButton-colorSecondary:hover {
            background-color: transparentize($dark-blue, 0.9);
        }

        .MuiFormControlLabel-label {
            @extend .typography-checkbox-label;
            margin-left: 4px;
        }
    }
}
