@import '../base/variables';

.complete-modal-container {
    padding: 40px;
    display: flex;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $dark-grey;

    .one-panel,
    .panel-right,
    .panel-left {
        width: 50%;

        .header {
            color: $very-dark-blue;
            font-family: 'futura-pt', 'Helvetica', 'Arial', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    .one-panel {
        width: 100%;

        .completed-date-picker {
            margin-bottom: 20px;
        }

        .MuiInputBase-root {
            width: 100%;
        }
    }

    .panel-left {
        text-align: center;
        padding-right: 10px;

        .text-1 {
            margin-bottom: 20px;
        }

        a {
            color: $provide-teal;
        }
    }

    .one-panel,
    .panel-right {
        padding-left: 10px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
            color: $provide-teal;
            font-weight: 700;
        }

        .loan-amount-input,
        .lender-select {
            margin-bottom: 20px;

            .MuiInputBase-root {
                width: 100%;
            }
        }

        .actions {
            text-align: right;
        }
    }
}
