@import '../base/variables';

.registration {
    background-color: #ffffff;
    mix-blend-mode: multiply;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-heading {
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 0;
        color: #000000;
    }

    .logo {
        margin-left: 20px;
        margin-top: 18px;
        margin-bottom: 15px;
        max-width: 150px;
    }

    .page-header {
        border-bottom: 1px solid #c4c3c3;
        margin-bottom: 40px;
    }

    .footer-container {
        @extend .typography-small;
        color: $dark-grey;
        margin-top: 20px;

        & a {
            color: $dark-grey;
            text-decoration: none;
        }

        .links {
            margin-top: 10px;
            line-height: 18px;

            & a {
                margin: 0 8px;
            }
        }
    }

    .container {
        width: 100%;
        //max-width: 500px;
        align-self: center;
        //margin: 20px;

        .branding-container {
            margin-bottom: 66px;
        }

        .registration-branding-container {
            margin-bottom: 1.7rem;
        }

        .content-container {
            width: 100%;
            background-color: white;
            padding: 40px;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);

            .body {
                @extend .typography-authentication-body;
                line-height: 24px;
            }

            .form-container {
                margin-bottom: 30px;
            }

            .form-messages {
                margin-bottom: 30px;
            }

            .heading {
                @extend .typography-card-title-brand-blue;
                margin: 0;
            }

            .button-panel {
                margin-top: 41px;
            }

            .login .button-panel {
                margin-top: 16px;
            }

            .text-field,
            .input-autocomplete {
                margin-top: 31px;
            }

            .forgot-password {
                @extend .typography-small;
                margin-top: 17px;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 900px) {
    .registration {
        background-image: none;

        &.form-heading-container {
            padding-top: 0;
        }

        .page-header {
            border-bottom: 1px solid #c4c3c3;
            margin-bottom: 0;
        }

        .container {
            align-self: auto;

            .branding-container {
                margin-bottom: 30px;
            }

            .content-container {
                padding: 40px 30px;
                margin: 20px;

                .form-container {
                    margin-bottom: 20px;
                }

                .form-messages {
                    margin-bottom: 20px;
                }

                .button-panel {
                    margin-top: 16px;
                }

                .text-field,
                .input-autocomplete {
                    margin-top: 20px;
                }
            }

            .footer-container {
                margin-top: 15px;
            }
        }
    }
}
