@import '../base/variables';

.snackbar {
    .MuiSnackbar-root {
        .MuiSnackbarContent-root {
            width: 644px;
            padding: 0;
            margin: 0;
            border-radius: 15px;
            background-color: white;

            .MuiSnackbarContent-message {
                padding: 0;
                border-radius: 15px;

                .MuiGrid-container {
                    .icon-container {
                        line-height: 0;
                        padding: 30px 28px;
                        color: white;
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;

                        &-success {
                            background-color: $snackbar-green;
                        }

                        &-error {
                            background-color: $snackbar-red;
                        }

                        &-info,
                        &-warning {
                            background-color: $snackbar-orange;
                        }

                        .MuiSvgIcon-root {
                            font-size: 19px;
                            width: 34px;
                            height: 34px;
                        }
                    }

                    .message-container {
                        @extend .typography-snackbar-message;
                        line-height: 22px;
                        padding-left: 28px;
                        display: flex;
                        align-items: center;
                        height: 94px;
                        max-width: 480px;
                    }
                }
            }
        }

        .MuiSnackbarContent-action {
            color: $dark-grey;
            margin-right: 10px;

            .MuiSvgIcon-root {
                width: 24px;
                height: 24px;
            }
        }
    }
}
