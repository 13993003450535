.bubble-text {
    position: relative;
    right: 0;
    border-radius: 0.4em;
    padding: 1em;
    min-width: 200px;

    // Arrow
    &:after {
        content: '';
        position: absolute;
        top: 10px;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-left: 0;
        margin-top: -10px;
        margin-left: -10px;
    }

    &.incoming {
        background: $red;
        color: $light-font;
    }

    &.outgoing {
        background: $very-light-grey;
        color: $dark-grey;
        margin-right: 15px;
    }

    // Arrow
    &.top-right {
        border-top-right-radius: 0;
        &.incoming:after {
            border-bottom: 0;
            right: -15px;
            border-top-color: $red;
        }
        &.outgoing:after {
            border-bottom: 0;
            right: -15px;
            border-top-color: $very-light-grey;
        }
    }

    // Arrow
    &.top-left {
        border-top-left-radius: 0;
        &.incoming:after {
            border-top: 0;
            left: -5px;
            border-right-color: $red;
        }
        &.outgoing:after {
            border-top: 0;
            left: -5px;
            border-right-color: $very-light-grey;
        }
    }
}
