@import '../../base/variables';

.stepper {
    margin-top: 30px;

    &.MuiStepper-root {
        background-color: inherit;
        padding: 0;
        width: 100%;

        .MuiStepIcon-root,
        .Mui-disabled {
            color: $provide-black;

            .MuiStepIcon-text {
                @extend .typography-button;
            }

            &.MuiStepIcon-active {
                color: $provide-teal;
            }

            &.MuiStepIcon-completed {
                color: $provide-black;
            }
        }

        .MuiPaper-root {
            padding: 0 38px 45px;
            margin-left: 30px;
            margin-top: 34px;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiStepContent-root {
            margin-left: 8px;
        }

        .MuiStepConnector-root {
            margin-left: 8px;
        }

        .MuiStepLabel-root {
            .MuiStepLabel-labelContainer {
                margin-left: 33px;
                margin-top: 3px;
            }

            .MuiStepLabel-label {
                @extend .typography-card-title;

                color: $provide-black !important;
                font-weight: 600 !important;
            }
        }
    }

    .form-wizard-skip-to-section {
        margin-top: 38px;
        width: 100%;

        @media (min-width: $breakpoint-md) {
            width: 50%;
        }

        .MuiLink-root {
            color: $red;
        }
    }
}
