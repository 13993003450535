@import '../../base/variables';

.broker-form {
    min-width: 400px;
    padding: 20px 36px 36px 36px;

    .MuiFormHelperText-root.Mui-error {
        margin-top: 6px;
    }

    .broker-form-scrollable {
        height: 500px;
        overflow-y: scroll;
        clear: both;
    }
}

.network-modal-header {
    color: $provide-teal;
    font-weight: 700;
}
