@import '../../base/variables';

.le-revenue-container-modal {
    padding: 40px 40px 30px 40px;
    min-width: 350px;

    .actions {
        text-align: right;
    }

    .header {
        color: $provide-teal;
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .btn-submit {
        margin-left: 10px;
        min-width: 67px;
        background-color: $provide-teal;
    }

    .btn-back {
        min-width: 67px;
    }

    .content {
        margin: 0 0 20px;
        min-height: unset;
        padding: 0;

        .assignee-list {
            width: 100%;
        }

        .MuiInputBase-root {
            width: 100%;

            input {
                text-align: right;
            }
        }
    }

    .link {
        margin-bottom: 20px;
    }
}
