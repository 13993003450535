@import '../base/variables';

.tabular-content-layout {
    .table-header {
        margin-bottom: 20px;
    }

    .typography-page-title {
        line-height: 44px;
    }

    .MuiTableCell-head {
        color: $dark-grey;
    }

    .MuiTableCell-body {
        color: $dark-grey;
    }

    .search-icon {
        position: relative;
    }

    .search-icon::before {
        position: absolute;
        content: '';
        width: 18px;
        height: 18px;
        left: 0;
        bottom: 0;
        top: -7px;
        background-image: url('../images/search-icon.svg');
        background-repeat: no-repeat;
    }

    .text-field-search {
        input::placeholder {
            position: relative;
            text-align: left;
        }

        input::-moz-placeholder {
            position: relative;
            text-align: left;
        }

        input:-moz-placeholder {
            position: relative;
            text-align: left;
        }

        input::-webkit-input-placeholder {
            position: relative;
            text-align: left;
        }
    }

    .table-row {
        &.draft {
            .MuiTableCell-body {
                color: $medium-grey;
            }

            .status-draft {
                color: $supporting-cyan;
                font-weight: 600;
            }
        }

        .status-matched,
        .status-pending,
        .status-in-progress {
            color: $red;
        }
    }
}
