@import '../../base/variables';

#bottomOfMessages {
    clear: both;
}

.view-lead {
    .view-lead-content {
        .app-bar {
            background-color: $main-bg;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            font-family: Roboto, Helvetica, Arial, sans-serif, serif;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        }

        .MuiPaper-root {
            color: $provide-grey-5;
            box-shadow: none;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
        }

        .notice {
            width: 100%;

            @media (min-width: $breakpoint-md) {
                width: 50%;
            }
        }

        .view-lead-applicants {
            .p4style-component-container {
                border-radius: 15px !important;

                .table-container {
                    border-bottom: 1px solid $light-grey;
                    padding-bottom: 20px;

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }
            }
        }

        .view-lead-lender-comms {
            .p4style-component-container {
                padding: 0 !important;
                border-radius: 15px !important;

                .p4style-component-header {
                    padding: $p4style-container-padding-vert $p4style-container-padding-horiz 24px
                        $p4style-container-padding-horiz;
                }

                .message-list {
                    .messages {
                        min-height: 300px;
                        max-height: 540px;
                        overflow: auto;
                        width: 100%;
                        margin-bottom: 0;

                        .message-row {
                            white-space: pre-line;
                            padding: 0 $p4style-container-padding-horiz 0 $p4style-container-padding-horiz;
                            margin-bottom: 30px;
                            clear: both;
                            font-weight: 400;

                            &.start-chat {
                                text-align: left;
                                color: $light-grey;
                                height: 50px;
                                font-size: 14px;
                                line-height: 50px;
                            }

                            &:first-of-type {
                                padding-top: 0;
                            }

                            &.is-sender {
                                float: right;

                                .bubble-text.top-right.outgoing {
                                    margin-right: 0;
                                }

                                .bubble-text.top-right.outgoing::after {
                                    border: none;
                                    right: 0;
                                }
                            }

                            &.role-borrower {
                                .bubble-text.incoming {
                                    background-color: $provide-borrower-color;
                                }

                                .bubble-text.incoming:after {
                                    border-right-color: $provide-borrower-color;
                                }
                            }

                            &.role-intermediary {
                                .bubble-text.incoming {
                                    background-color: $provide-intermediary-color;
                                }

                                .bubble-text.incoming:after {
                                    border-right-color: $provide-intermediary-color;
                                }
                            }

                            &.role-slam {
                                .bubble-text.incoming {
                                    background-color: $provide-lender-color;
                                }

                                .bubble-text.incoming:after {
                                    border-right-color: $provide-lender-color;
                                }
                            }

                            &.role-admin {
                                color: $dark-grey;
                                background-color: $white;

                                .bubble-text {
                                    border: solid 1px $light-grey;
                                    background-color: $white;
                                    color: $dark-grey;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: 10px;
                                        width: 2px;
                                        height: 0;
                                        border: 13px solid transparent;
                                        border-left: 0;
                                        margin-top: -10px;
                                        margin-left: -15px;
                                        z-index: 10000;
                                    }

                                    &.top-left {
                                        border-top-left-radius: 0;

                                        &.incoming:after {
                                            border-top: 0;
                                            left: -5px;
                                            top: 9px;
                                            border-right-color: $light-grey;
                                        }

                                        &.incoming:before {
                                            border-top: 0;
                                            left: 0;
                                            border-right-color: $white;
                                        }
                                    }
                                }
                            }

                            .message-body,
                            .message-timestamp {
                                font-weight: 400;
                            }

                            .message-body {
                                line-height: 24px;
                            }

                            .bubble-text {
                                margin-top: 12px;
                                padding: 15px 9px 5px 15px;
                                min-width: 250px;
                            }
                        }
                    }

                    form {
                        background-color: $info-box-bg;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;

                        .chat-form {
                            padding: $p4style-container-padding-vert $p4style-container-padding-horiz;

                            textarea {
                                resize: none;
                                width: 100%;
                                background-color: white;
                                border-radius: 3px;
                                border: none;
                            }
                        }
                    }
                }
            }
        }

        .view-lead-docs {
            button:disabled {
                background-color: white !important;
            }

            .p4style-component-container {
                padding-left: 0;
                padding-right: 0;
                border-radius: 15px !important;

                .p4style-component-header {
                    padding-left: 30px;
                    padding-right: 30px;

                    .secondary-button {
                        transition: none;
                        padding: 0;

                        .MuiSvgIcon-root {
                            width: 26px;
                            height: 26px;
                            margin-left: 10px;
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }

                @media only screen and (max-width: 600px) {
                    .p4style-component-body {
                        .document-row {
                            display: block;
                            padding: 10px 20px 10px 20px;

                            .doc-icon {
                                display: none;
                            }

                            .doc-filename,
                            .doc-type {
                                margin: 0;
                            }

                            .doc-actions {
                                width: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}
