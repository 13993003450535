@import '../base/variables';

.badge-container {
    display: block !important;
}

.badge {
    background-color: $supporting-cyan;
    color: $white;
    font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'serif';
    font-size: 12px;
    border-radius: 20px;
    padding: 5px;
    font-weight: 500;
}

.badge-default {
    background-image: $provide-linear-gradient-pink;
}

.badge-super-admin {
    background-color: $white;
    color: $provide-admin-color;
    border: 1px solid $provide-admin-color;
}

.badge-admin {
    background-color: $white;
    color: $provide-admin-color;
    border: 1px solid $provide-admin-color;
}

.badge-borrower {
    background-color: $provide-borrower-color;
}

.badge-intermediary {
    background-color: $provide-intermediary-color;
}

.badge-lender {
    background-color: $provide-lender-color;
}

.badge-admin-note-alert {
    background-color: $red;
}
