@import '../../../base/variables';

.admin-case-chat-container {
    .p4style-component-container {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}
