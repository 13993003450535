@import '../../base/variables';

.le-header-container {
    justify-content: space-between;
    display: flex;
    align-items: center;

    label {
        font-family: Roboto, Helvetica, Arial, sans-serif, serif;
    }

    .header-text {
        display: block;

        .text > h1 {
            color: $provide-black;
            text-align: left;
            margin-bottom: 10px;
            margin-top: 0;
            font-size: 30px;
            font-family: $provide-font-family-degular-display;
            font-weight: 500;
        }

        .btn-mobile {
            display: none;
        }
    }

    .header-toolbar-mobile {
        display: none;
        margin-bottom: 10px;
        font-family: $provide-font-family-roboto;

        label,
        button,
        input {
            font-family: $provide-font-family-roboto;
        }

        .divider {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .btn-clear-all {
            height: 45px;
            padding-top: 11px;
        }

        .container {
            box-shadow: none;
            width: 100%;

            // To remove top border from accordion
            &:before {
                background-color: unset;
            }

            .details {
                display: block !important;
                padding: 10px 0;
            }

            .select-updates-filter {
                .label {
                    color: $supporting-cyan;
                    font-weight: bold;
                }
            }

            .select-assignee-filter,
            .select-updates-filter,
            .select-status,
            .select-bs,
            .search {
                width: 100%;
                margin-bottom: 20px;
            }

            .switch-expired {
                margin-bottom: 20px;
            }
        }
    }

    .header-toolbar {
        display: flex;
        font-family: $provide-font-family-roboto;

        label,
        button,
        input {
            font-family: $provide-font-family-roboto;
        }

        .btn-create {
            margin-left: 0 !important;
        }

        .btn-clear-all {
            margin-right: 20px !important;
        }

        .btn-add {
            height: 45px;
            padding-top: 11px;
            font-family: Roboto, Helvetica, Arial, sans-serif;
        }

        .primary-button {
            margin-right: 0;
            height: 45px;
        }

        .show-expired-toggle {
            margin-right: 20px;
            margin-left: 0;
            color: $dark-grey;

            span {
                font-family: Roboto, Helvetica, Arial, sans-serif, serif;
            }
        }

        .search {
            input {
                padding: 18.5px 14px;
                height: 9.1px;
            }

            .MuiInputLabel-outlined {
                &.MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.75);
                }
            }

            label {
                transform: translate(14px, 18px) scale(1);
            }

            & .MuiOutlinedInput-root {
                margin-right: 20px;
            }
        }

        .select-updates-filter {
            .label {
                color: $supporting-cyan;
                font-weight: bold;
            }
        }

        .select-status,
        .select-assignee-filter,
        .select-updates-filter,
        .select-bs,
        .select-role,
        .select-finance-type {
            .MuiInputBase-formControl {
                height: 3.3rem;
            }
        }

        .select-status,
        .select-assignee-filter,
        .select-updates-filter,
        .select-bs,
        .select-role,
        .select-finance-type {
            min-width: 120px;
            margin-right: 20px;

            .MuiSelect-iconOutlined {
                top: calc(50% - 8px);
            }

            .MuiInputLabel-outlined {
                &.MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.75);
                }
            }

            label {
                transform: translate(14px, 18px) scale(1);
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .le-header-container {
        display: block;
    }
    .header-toolbar-mobile {
        display: block !important;

        .btn-clear-all {
            width: 100%;
        }
    }
    .header-toolbar {
        display: none !important;
    }
    .header-text {
        display: flex !important;
        justify-content: space-between;

        .btn-mobile {
            display: block !important;
            margin: 2rem 0 0 0;
        }
    }
}
