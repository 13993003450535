@import '../../base/variables';

.le-table-container {
    height: 70vh;
    width: 100%;
    background-color: $white;

    .le-table-cell-content-center {
        justify-content: center;
    }

    .le-table-cell-assigned-to {
        .icon {
            width: 24px;
            height: 24px;
            background-color: $light-grey;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 10px;
        }

        &:hover {
            text-decoration: underline;
        }

        .name-active {
            color: $dark-blue;
            font-weight: 600;
        }
    }

    .le-table-cell-content-right {
        justify-content: flex-end;
    }

    .le-table-cell-content-left {
        justify-content: flex-start;
    }

    .MuiDataGrid-root {
        border-radius: 0;
    }

    .MuiTablePagination-select {
        font-family: $provide-font-family-roboto !important;
    }

    .MuiDataGrid-row {
        cursor: pointer;

        &:hover {
            .le-table-cell-context-menu {
                opacity: 1;
            }
        }
    }

    .MuiTablePagination-toolbar,
    .MuiDataGrid-colCellTitleContainer {
        span.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
            display: none;
        }
    }

    .MuiDataGrid-colCell,
    .MuiDataGrid-footer,
    .MuiTablePagination-root p {
        @extend .typography-default;
        color: $provide-black;
    }

    .le-table-cell {
        @extend .typography-default;
        color: $provide-black;

        .active {
            color: $red;
        }
    }

    .le-table-cell-context-menu {
        opacity: 0;
    }

    .le-table-header-no-separator {
        .MuiDataGrid-columnSeparator {
            display: none;
        }
    }

    .le-table-header-fixed-height {
        min-height: 56px;
    }

    .le-table-cell-status > div {
        line-height: 1.5 !important;
        width: 50%;
    }

    .le-table-cell-revenue {
        font-weight: 600;
        cursor: pointer;
    }

    .label-draft {
        color: $red;
        font-weight: 600;
    }

    .le-table-cell-terms-sent {
        .MuiFormControlLabel-label {
            font-family: $provide-font-family-roboto;
        }
    }
}
