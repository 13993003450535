.text-label {
    @extend .typography-default;

    .tooltip-anchor {
        position: relative;
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;

        .tooltip-icon {
            width: 18px;
            height: 18px;
        }
    }
}
