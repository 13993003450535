@import '../base/variables';

.intermediary-registration {
    height: 100vh;
    align-content: flex-start;
    background-color: #ffffff;

    .form-heading {
        color: $dark-grey;
        font-size: 20px;
    }

    .dpl-panel {
        font-size: 14px;
        color: $dark-grey;
        padding-top: 15px;
        border-top: 1px solid #f2f2f2;
    }

    .privacy-panel {
        font-size: 12px;
        color: $dark-grey;
        text-align: center;
        border-top: 1px solid #f2f2f2;
    }

    .privacy-panel a {
        color: $provide-teal;
    }

    .strong {
        font-weight: 600;
    }

    .button-submit {
        background-color: $provide-teal !important;
    }

    .left-container {
        background-color: #ffffff;
        height: 100%;
        align-content: flex-start;
    }

    .right-container {
        background-image: url('../images/product-registration-bg.png');
        background-size: cover;
        background-position: right;
        background-color: $black;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 20px;
    }

    .heading {
        font-size: 65px;
        margin-bottom: 0;
        color: $provide-purple;
        text-align: center;
        line-height: 65px;
        font-weight: 400;
        font-family: $provide-font-family-degular-display;
    }

    .sub-heading {
        font-size: 20px;
        margin-bottom: 0;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
    }

    .logo {
        margin-left: 20px;
        margin-top: 40px;
        margin-bottom: 15px;
        max-width: 150px;
    }

    .page-header {
        border-bottom: 1px solid #c4c3c3;
        background-color: #ffffff;
        height: 100px;
    }

    .footer-container {
        @extend .typography-small;
        color: $dark-grey;
        background-color: #ffffff;
        margin-bottom: 20px;

        & a {
            color: $dark-grey;
            text-decoration: underline;
        }

        .links {
            margin-top: 10px;
            line-height: 18px;

            & a {
                margin: 0 8px;
            }
        }
    }

    .container {
        align-self: center;
        justify-content: center;
        background-color: #ffffff;

        .registration-branding-container {
            margin-bottom: 1.7rem;
        }

        .content-container {
            background-color: white;
            padding: 40px 40px 20px 40px;

            .body {
                @extend .typography-authentication-body;
                line-height: 24px;
            }

            .form-container {
                margin-bottom: 30px;
            }

            .form-messages {
                margin-bottom: 30px;
            }

            .heading {
                @extend .typography-card-title-brand-blue;
                margin: 0;
            }

            .button-panel {
                margin-top: 40px;
                margin-bottom: 20px;
            }

            .text-field,
            .input-autocomplete {
                margin-top: 31px;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 900px) {
    .intermediary-registration {
        &.form-heading-container {
            padding-top: 0;
        }

        .left-container {
            align-content: flex-start;
        }

        .right-container {
            display: none;
        }

        .container {
            align-self: auto;

            .branding-container {
                margin-bottom: 30px;
            }

            .content-container {
                .form-container {
                    margin-bottom: 20px;
                }

                .form-messages {
                    margin-bottom: 20px;
                }

                .text-field,
                .input-autocomplete {
                    margin-top: 20px;
                }
            }

            .footer-container {
                margin-top: 15px;
            }
        }
    }
}
