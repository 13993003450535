@import '../../base/variables';

.lle-list {
    display: flex;
    justify-content: center;

    .contract-unsigned {
        color: $red;
        font-weight: 600;
        margin: 50px 0;
    }
}
