@import '../../base/variables';

.view-lead-summary,
.view-enquiry-summary {
    .assign-to {
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }

        .icon {
            width: 24px;
            height: 24px;
            background-color: $light-grey;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 10px;
        }

        .name-active {
            color: $dark-blue;
            font-weight: 600;
        }
    }

    .contract-previously-contacted-lenders {
        color: $provide-teal;
        font-weight: 600;
    }

    .chase-date-container,
    .chase-note-container {
        cursor: pointer;
        background-color: #ffffff;
        padding: 15px 20px 15px 20px;
        border-radius: 20px;
        border: 1px solid #c7c7c7;

        & div {
            display: flex;
            justify-content: space-between;
        }
    }

    .chase-note-container {
        .read-more {
            color: $provide-teal;
            text-decoration: underline;
            margin-top: 10px;
        }
    }
}
