@import '../../base/variables';

//Product list active colour when list is open
.product-select-product-active {
    color: $provide-teal !important;
}

.admin-terms-edit,
.slam-terms-edit-drawer {
    .MuiPaper-root {
        min-width: 600px;
        padding: 30px 30px 0 40px;
    }

    .edit-terms-container {
        margin-top: 20px;

        .item {
            padding: 20px;
            background-color: $white;
            border-top: 1px solid $light-grey;
            border-left: 1px solid $light-grey;
            border-right: 1px solid $light-grey;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.last {
                border-bottom: 1px solid $light-grey;
            }

            .name {
                &.filled {
                    font-weight: 600;
                    color: $dark-grey;
                }

                &.empty {
                    color: $medium-grey;
                    font-size: 1rem;
                }
            }

            .value {
                display: flex;
                justify-content: flex-end;

                .empty {
                    font-size: 26px;
                    color: $very-light-grey;
                }

                .filled {
                    font-size: 26px;
                    color: $provide-teal;
                }

                .dirty {
                    font-size: 26px;
                    color: $provide-supporting-cyan;
                }
            }
        }

        .empty {
            color: $very-light-grey;
            font-size: 26px;
        }
    }

    .progress-container {
        background-color: $very-light-grey;
        padding: 20px;
        display: flex;
        margin-top: 20px;

        .title {
            font-size: 1.4rem;
            color: $dark-grey;
            text-align: left;
            padding: 0 0 10px 0;
        }

        .last-updated-title {
            color: $medium-grey;
        }

        .last-updated-date {
            color: $dark-grey;
            font-weight: 600;
        }

        .progress {
            width: 100%;

            .title {
                font-size: 1.4rem;
            }

            .progress-bar {
                background-color: $light-grey;
                height: 7px;
                border-radius: 5px;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;

        .sub-header {
            display: flex;
        }

        .icon {
        }

        .title {
            color: $medium-grey;
            padding: 0;
        }
    }

    .actions {
        margin-top: 10px;
        margin-bottom: 30px;
        display: flex;
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        background-color: $white;
        padding: 20px 0 20px 0;
        z-index: 1;
        right: 0;
        width: 100%;

        .btn-submit {
            min-width: 100px;
            background-color: $provide-teal;

            &:hover {
                background: lighten($provide-grey-5, 10);
            }

            .progress {
                height: 20px !important;
                width: 20px !important;
            }
        }
    }
}

.admin-terms-edit {
    background-color: $white !important;
    padding: 30px 30px 0 40px !important;

    .progress-container {
        margin-top: 0 !important;
    }

    .MuiPaper-root {
        min-width: unset;
        width: unset;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        background-color: $very-light-grey !important;
    }
}

.admin-terms-edit,
.terms-and-fees-container {
    background-color: $main-bg;
    padding: 30px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: $dark-grey;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .product-select-container {
        display: flex;
        align-items: center;
        background-color: $white;
        border: 1px solid $light-grey;
        border-radius: 4px;
        padding: 20px;
        justify-content: center;

        .header {
            font-weight: 600;
        }

        .product-select {
            margin-left: 20px;
            min-width: 200px;

            .active {
                color: $provide-teal;
            }
        }
    }

    .progress-container {
        background-color: $white;
        border: 1px solid $light-grey;
        border-radius: 5px;
        padding: 20px;
        margin-top: 20px;

        .btn-edit-terms {
            font-family: Roboto, Helvetica, Arial, sans-serif;
            background-color: $provide-teal;
        }

        .progress-rate,
        .progress-actions {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .progress-rate {
            font-size: 1.2rem;
            font-weight: 600;
        }

        .progress-bar-container {
            margin-top: 12px;

            .progress-bar {
                background-color: $light-grey;
                height: 7px;
                border-radius: 5px;
            }
        }
    }

    .terms-and-fees-items-container {
        margin-top: 20px;

        .item {
            padding: 20px;
            background-color: $white;
            border-top: 1px solid $light-grey;
            border-left: 1px solid $light-grey;
            border-right: 1px solid $light-grey;
            display: flex;
            align-items: center;

            &.last {
                border-bottom: 1px solid $light-grey;
            }

            .name {
                &.filled {
                    font-weight: 600;
                    color: $dark-grey;
                }

                &.empty {
                    color: $medium-grey;
                }
            }

            .optional {
                color: $supporting-cyan;
                font-size: 12px;
            }

            .value {
                text-align: right;
                padding-right: 20px;
                display: flex;
                justify-content: flex-end;

                .empty {
                    width: 24px;
                    height: 24px;
                    background-color: $very-light-grey;
                    border-radius: 50%;
                }

                .filled {
                    font-size: 26px;
                    color: $provide-teal;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .slam-terms-edit-drawer {
        .MuiPaper-root {
            min-width: 100%;
        }
    }
}

@media only screen and (max-width: 960px) {
    .terms-and-fees-container {
        .progress-container {
            .progress-actions {
                margin-top: 20px;
            }
        }
    }
}
